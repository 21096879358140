import React, {useContext} from 'react';
import {
    Avatar,
    Box,
    Stack,
    Typography,
    IconButton,
    Button,
    useTheme
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import thousandSeparator from '../../utils/numberUtils';
import ConfigContext from "../../context/ConfigContext";

const OrderDetail = ({ order, close, createInvoice, downloadInvoice, sendInvoice }) => {
    const {baseUrl} = useContext(ConfigContext);

    const billingDataProvided = () =>
        order?.billing_name && order?.billing_country && order?.billing_city && order?.billing_postal_code && order?.billing_address;

    return (
        <div style={{ padding: '48px' }}>
            <IconButton onClick={close} color="primary" aria-label="close" style={{ position: 'absolute', top: 10, left: 10 }}>
                <CloseOutlinedIcon />
            </IconButton>
            <Stack spacing={3}>
                <Stack direction="row" spacing={2}>
                    <Avatar sx={{ width: 69, height: 69, background: '#9ED4B6', color: '#478A64' }}>
                        {order?.first_name?.charAt(0)}{order?.last_name?.charAt(0)}
                    </Avatar>
                    <Stack spacing={1} style={{ alignSelf: 'center' }}>
                        <Typography variant="headline6">{order?.name}</Typography>
                        <Typography variant="caption" style={{ color: '#478A64' }}>Elfogadott</Typography>
                    </Stack>
                </Stack>

                <Typography variant="subtitle1" color='primary.600'>Vevői adatok</Typography>
                <table className="order-detail-table">
                    <tbody>
                    <tr>
                        <td><Typography variant="subtitle2">Név</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.name}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Telefonszám</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.phone}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Email</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.email}</Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" color='primary.600'>Számlázási adatok</Typography>
                <table className="order-detail-table">
                    <tbody>
                    <tr>
                        <td><Typography variant="subtitle2">Név</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.billing_name || 'Nincs megadva'}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Adószám</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.billing_vat_number || '-'}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Cím</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">
                                {order?.billing_country || '-'}, {order?.billing_postal_code} {order?.billing_city} {order?.billing_address}
                            </Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" color='primary.600'>Rendelés adatai</Typography>
                <table className="order-detail-table">
                    <tbody>
                    <tr>
                        <td><Typography variant="subtitle2">Fizetési mód</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.payment === 'card' ? 'Bankkártya' : 'Egyéb'}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Ár</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{thousandSeparator(order?.price)} Ft</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td><Typography variant="subtitle2">Megjegyzés</Typography></td>
                        <td style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2">{order?.comment || 'Nincs megjegyzés'}</Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {order?.ordered_products && <Stack spacing={2}>
                    <Typography variant='subtitle1' color='primary.600'>Rendelt termékek:</Typography>
                    {order?.ordered_products?.map(p => <Box key={`product_${p?.id}`} display='flex'
                                                            justifyContent='space-between' alignItems='center' gap={2}>
                        <Avatar src={baseUrl + p?.image}/>
                        <Typography variant='caption'>{p?.name}</Typography>
                        <Typography>{thousandSeparator(p?.price)} Ft - ({p?.quantity} db)</Typography>
                    </Box>)}
                </Stack>}

                <Stack direction="row" spacing={2} sx={{ padding: '20px', background: '#fff' }}>
                    {billingDataProvided() ? (
                        !order?.invoice_id ? (
                            <Button
                                startIcon={<ReceiptRoundedIcon />}
                                onClick={() => createInvoice(order?.id)}
                                variant="outlined"
                            >
                                Számla elkészítése
                            </Button>
                        ) : (
                            <>
                                <Button
                                    startIcon={<ReceiptRoundedIcon />}
                                    onClick={() => downloadInvoice(order?.id)}
                                    variant="outlined"
                                >
                                    Számla letöltése
                                </Button>
                                <Button
                                    startIcon={<ReceiptRoundedIcon />}
                                    onClick={() => sendInvoice(order?.id)}
                                    variant="outlined"
                                >
                                    Számla kiküldése
                                </Button>
                            </>
                        )
                    ) : (
                        <Button startIcon={<ReceiptRoundedIcon />} disabled variant="outlined">
                            Nincs megadva számlázási adat
                        </Button>
                    )}
                </Stack>

            </Stack>
        </div>
    );
};

export default OrderDetail;
