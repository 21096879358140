import {TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import locale from "date-fns/locale/hu";
import React from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";


const CustomDatePicker = ({errors, setErrors, data, setData, name, validators, ...rest}) => {

    const handleChange = (newValue) => {
        let updatedValue = newValue;
        if (newValue instanceof Date) {
            const year = newValue.getFullYear();
            const month = String(newValue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(newValue.getDate()).padStart(2, '0');
            updatedValue = `${year}-${month}-${day}`;
        }
        setData({...data, [name]: updatedValue});
    }

    const handleBlur = (newValue) => {
        for (let validator of validators[name]) {
            const {valid, error} = validator(newValue)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else {
                setErrors({...errors, [name]: false})
            }
        }
    }

    return <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DatePicker
            value={data[name]}
            onChange={(e) => {
                handleBlur(e)
                handleChange(e)
            }}
            onBlur={handleBlur}
            {...rest}
            // InputAdornmentProps={{ position: 'start' }}
            renderInput={params => <TextField error={Boolean(errors[name])} helperText={errors[name] && errors[name]} id={name} variant='standard' name={name} {...params} />}
        />
    </LocalizationProvider>
}

export default CustomDatePicker