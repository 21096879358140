const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
}

const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const color = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');

    return `#${color}`;
}

const stringToLightColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Generate the color
    let color = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');

    // Adjust to ensure the color is light
    // Light colors are generally high in brightness, so we can manipulate the color to keep values high.
    const r = Math.min(255, parseInt(color.substring(0, 2), 16) + 150); // Ensure red is bright
    const g = Math.min(255, parseInt(color.substring(2, 4), 16) + 150); // Ensure green is bright
    const b = Math.min(255, parseInt(color.substring(4, 6), 16) + 150); // Ensure blue is bright

    // Return the modified light color
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0').toUpperCase()}`;
}

const ensureTrailingSlash = url => {
    if (url) {
        const strUrl = url?.toString()
        return strUrl?.endsWith('/') ? strUrl : strUrl + '/';
    }
    return url
}

const formatTime = (time) => time.split(':').slice(0, 2).join(':');

const getNameOfDay = (date) => {
    const d = new Date(date)
    const daysInHungarian = ["vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat"];
    return daysInHungarian[d.getDay()];
}

const mergeDataIfNotNull = (objects) => {
    if (objects.some(obj => obj === null)) return null
    return objects.reduce((acc, obj) => {
        return { ...acc, ...obj };
    }, {});
}


export {truncateText, stringToColor, stringToLightColor, ensureTrailingSlash, getNameOfDay, formatTime, mergeDataIfNotNull}