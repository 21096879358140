import {format} from "date-fns";

const formatHuman = (date) => format(date, "yyyy-MM-dd")

const days_en = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
]

const days = {
    monday: 'hétfő',
    tuesday: 'kedd',
    wednesday: 'szerda',
    thursday: 'csütörtök',
    friday: 'péntek',
    saturday: 'szombat',
    sunday: 'vasárnap',
}

const extractTime = (date) =>
    `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`

export {formatHuman, days, days_en, extractTime}