const serviceTemplate = {
    category: '',
    description: '',
    id: 1,
    image: '',
    is_accept_automatically: true,
    is_pay_cash_or_card: true,
    is_pay_online: true,
    is_public: true,
    length: 1,
    name: '',
    automatic_invoice: false,
    mandatory_billing_details: 'optional',
    accept_after: 0,
    accept_until: 60,
    is_pay_advance: false,
    advance_amount: 0,
    cancellable: false,
    vat_percent: 27,
}

export default serviceTemplate