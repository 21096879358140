import AuthBase from "../AuthBase";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import workHourTemplate from "./WorkHourTemplate";
import Loading from "../../components/Loading";
import _ from "lodash";
import WorkHourPicker from "../../components/WorkHourPicker";
import Leaves from "./components/Leaves";
import {Prompt} from "react-router-dom";
import {useSnackbar} from "notistack";


const OpeningHours = () => {
    const [openingHours, setOpeningHours] = useState(workHourTemplate)
    const [editedOpeningHours, setEditedOpeningHours] = useState(workHourTemplate)
    const [business, setBusiness] = useState({})
    const [loading, setLoading] = useState(false)
    const {getData: fetchOpeningHours, updateData: updateOpeningHours} = useCallDataApi('work-hour')
    const {getData: fetchBusiness} = useCallDataApi('business')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchOpeningHours('get_for_business'),
            fetchBusiness('get_business')
        ]).then(([o, b]) => {
            setBusiness(b)
            setOpeningHours(o)
            setEditedOpeningHours(_.cloneDeep(o))
        }).finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasUnsavedChanges = JSON.stringify(openingHours) !== JSON.stringify(editedOpeningHours)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault()
                event.returnValue = "" // Required for modern browsers
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);


    const savePageData = () => {
        setLoading(true)
        updateOpeningHours('update_business/', editedOpeningHours)
            .then(o => {
                setOpeningHours(o)
                setEditedOpeningHours(_.cloneDeep(o))
                enqueueSnackbar('Mentve', {variant: 'success'})
            })
            .finally(() => setLoading(false))
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            variant: 'contained',
            size: 'large',
            disabled: !hasUnsavedChanges,
            onClick: savePageData
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            endIcon: <SaveRoundedIcon/>,
            variant: 'contained',
            size: 'small',
            disabled: !hasUnsavedChanges,
            onClick: savePageData
        }
    }]


    return <AuthBase label='Nyitvatartás' buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Grid container spacing={2}>

                {/*Opening hours*/}
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Nyitvatartási idő</Typography>
                        <Typography variant='body2' color='#536B74'>
                            Add meg mikor van nyitva vállalkozásod. A
                            kollégáknál csak ebben az idő intevallumban lehet foglalni időpontot!
                        </Typography>
                        <Typography variant='body2' color='#ff0000' marginBottom={2}>
                            Ez az menüpont a lábléc szekcióban látható nyitvatartást állítja, ami független a kollégák
                            munkaidejétől!
                            Amennyiben azt szeretnéd módosítani, hogy mely időpontok legyenek elérhetőek a
                            foglaló felületen, azt a <a href='/colleagues'>kollégák</a> menüpontban tudod megtenni.
                        </Typography>
                        <WorkHourPicker
                            editedOpeningHours={editedOpeningHours}
                            setEditedOpeningHours={setEditedOpeningHours}
                        />
                    </div>
                </Grid>

                {/*Leaves*/}
                <Grid item xs={12}>
                    <Leaves businessId={business?.id}/>
                </Grid>
            </Grid>
        </div>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />

        <Loading isLoading={loading}/>

    </AuthBase>
}

export default OpeningHours