import {days, days_en} from "../utils/dateUtils";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TimeRangePicker2 from "./TimeRangePicker2";
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import _ from "lodash";

const WorkHourPicker = ({editedOpeningHours, setEditedOpeningHours}) => {
    const addNewOpeningHour = (day) => {
        editedOpeningHours[day] = [...editedOpeningHours[day], {start: '09:00', end: '21:00'}]
        setEditedOpeningHours({...editedOpeningHours})
    }

    const removeOpeningHour = (day, idx) => {
        const newObj = _.cloneDeep(editedOpeningHours)
        newObj[day] = newObj[day].filter(wh => wh?.id !== idx)
        setEditedOpeningHours(newObj)
    }

    const editOpeningHour = (start, end, day, idx) => {
        const newObj = _.cloneDeep(editedOpeningHours)
        newObj[day] = [...newObj[day].map(oh => {
            if (oh?.id === idx) {
                const newOh = {start: start, end: end}
                return {...oh, ...newOh}
            }
            else return oh
        })]
        setEditedOpeningHours(newObj)
    }

    return <Stack spacing={1}>
        {days_en.map(day => <Stack key={`day-${day}`} direction={{xs: 'column', md: 'row'}} spacing={1} alignItems='center' sx={{flexWrap: 'wrap', gap: '10px'}}>
            <Box sx={{width: '100px'}}>
                <Typography variant='subtitle2'>{days[day]}</Typography>
            </Box>
            {editedOpeningHours?.[day]?.map(oh => <TimeRangePicker2
                key={`opening-hour-${day}-${oh?.id}`}
                start={oh?.start}
                end={oh?.end}
                onChange={(start, end) => editOpeningHour(start, end, day, oh?.id)}
                onDelete={() => removeOpeningHour(day, oh?.id)}
            />)}
            <IconButton color="primary" onClick={() => addNewOpeningHour(day)}>
                <AddCircleRoundedIcon/>
            </IconButton>

        </Stack>)}
    </Stack>
}

export default WorkHourPicker