import {createContext, useContext} from "react";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import AuthContext from "./AuthContext";

const AuthConfigContext = createContext();

export default AuthConfigContext;

export const AuthConfigProvider = ({ children }) => {
    const [billingServices, setBillingServices] = useState([])
    const {getData: fetchBusiness} = useCallDataApi('business')
    const {authTokens} = useContext(AuthContext)
    useEffect(() => {
        if (authTokens) fetchBusiness('get_billing_services').then(b => {
            setBillingServices(b)
        })
    }, [authTokens])

    const billingAvailable = () => Boolean(billingServices.find(b => b.active))

    const contextData = {
        billingServices,
        setBillingServices,
        billingAvailable
    }

    return <AuthConfigContext.Provider value={contextData}>{children}</AuthConfigContext.Provider>
}