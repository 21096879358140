import React, { useState } from "react";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    useTheme,
} from "@mui/material";
import {useSnackbar} from "notistack";

const GeneralStepper = ({ steps, onFinish }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(false);
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()

    const handleNext = () => {
        const currentStep = steps[activeStep];
        // Validate current step
        if (currentStep?.validate) {
            const {valid, error} = currentStep.validate();
            if (!valid) {
                enqueueSnackbar(error, {variant: 'error'})
                return;
            }
        }

        // Move to the next step or complete
        if (activeStep < steps.length - 1) {
            setActiveStep((prevStep) => prevStep + 1);
        } else {
            setCompleted(true);
            if (onFinish) {
                onFinish(); // Call onFinish when all steps are completed
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted(false);
    };

    return (
        <Box sx={{ width: "100%" }} className="reservation-stepper">
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{ color: theme.palette.primary[600] }}
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                                color: "primary.600", // circle color (COMPLETED)
                            },
                            "& .MuiStepLabel-root .Mui-active": {
                                color: "primary.600", // circle color (ACTIVE)
                            },
                            "& .MuiStepLabel-root .MuiStepIcon-text": {
                                fill: "#fff", // circle's number (ACTIVE)
                            },
                        }}
                    >
                        <StepLabel />
                    </Step>
                ))}
            </Stepper>

            <Box sx={{ mt: 2 }}>
                {completed ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Kész!
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </>
                ) : (
                    <>
                        <Box>{steps[activeStep].component}</Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="outlined"
                            >
                                Vissza
                            </Button>
                            <Button onClick={handleNext} variant="contained">
                                {activeStep === steps.length - 1 ? "Kész" : "Következő"}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default GeneralStepper;
