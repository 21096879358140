import {Avatar, Stack, TextField, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {useContext, useRef, useState} from "react";
import ConfigContext from "../../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import {
    validateMaxFileSize,
    validateMaxLength,
    validateNonNegativeNumber, validateNumber,
    validateRequired
} from "../../../components/formValidation/validators";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomImageField from "../../../components/formValidation/CustomImageField";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";

const ExtraItemData = ({reference, obj}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const [data, setData] = useState({
        name: obj?.name || '',
        price: obj?.price || 0,
        description: obj?.description || '',
        image: obj?.image || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        price: false,
        description: false,
        image: false,
    })

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        price: [validateRequired, validateNumber, validateNonNegativeNumber],
        description: [validateRequired, value => validateMaxLength(255, value)],
        image: [validateRequired, file => validateMaxFileSize(file, MAX_IMG_SIZE)]
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Név*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='name'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Kategória rövid leírása*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='description'
                variant='standard'
                multiline
                minRows={5}
            />
            <Typography variant='caption' color='primary.main'>Ár*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='price'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Kép*</Typography>
            <CustomImageField
                data={data}
                setData={setData}
                name='image'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
        </Stack>
    </ErrorHandlingForm>
}

export default ExtraItemData