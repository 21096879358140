import {MenuItem, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import useCallDataApi from "../../../../hooks/data";
import CustomSelect from "../../../../components/formValidation/CustomSelect";


const Category = ({reference, data, setData}) => {
    const [categories, setCategories] = useState([]);
    const {getData: fetchCategory} = useCallDataApi('category')
    useEffect(() => {
        fetchCategory('get_for_business').then(c => setCategories(c));
    }, []);
    

    const [errors, setErrors] = useState({
        category: false,
    });

    const validators = {
        category: [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='category'>
                {categories.map((c, i) => <MenuItem key={`category_${i}`} value={c?.id}>{c?.name}</MenuItem>)}
            </CustomSelect>
        </Stack>
    </ErrorHandlingForm>
}

export default Category