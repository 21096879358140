import {Stack} from "@mui/material";
import {useContext, useState} from "react";
import ConfigContext from "../../../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../../components/formValidation/CustomTextField";
import CustomImageField from "../../../../components/formValidation/CustomImageField";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import {
    validateDividableBy,
    validateDivisibleBy,
    validateMaxFileSize,
    validateMaxLength, validateMaxValue, validateMinValue, validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../../../components/formValidation/validators";

const ServiceData = ({reference, obj}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const [data, setData] = useState({
        name: obj?.name || '',
        length: obj?.length || '',
        description: obj?.description || '',
        image: obj?.image || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        length: false,
        description: false,
        image: false,
    })

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        length: [validateRequired, validateNumber, validateNonNegativeNumber, value => validateMinValue(value, 5), value => validateMaxValue(value, 1440), value => validateDividableBy(value, 5)],
        description: [validateRequired, value => validateMaxLength(255, value)],
        image: [validateRequired, file => validateMaxFileSize(file, MAX_IMG_SIZE)]
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Név (max 255 karakter)*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='name'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Szolgáltatás rövid leírása*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='description'
                variant='standard'
                multiline
                minRows={5}
            />
            <Typography variant='caption' color='primary.main'>Szolgáltatás hossza percben*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='length'
                variant='standard'
                placeholder='5 vagy annak többszöröse kell, hogy legyen pl.: 10, 30...'
            />
            <Typography variant='caption' color='primary.main'>Szolgáltatás képe*</Typography>
            <CustomImageField
                data={data}
                setData={setData}
                name='image'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
        </Stack>
    </ErrorHandlingForm>
}

export default ServiceData