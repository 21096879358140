import React, {useImperativeHandle} from 'react';
import {useSnackbar} from "notistack";

const ErrorHandlingForm = ({reference, children, errors, setErrors, data, setData, validators, customValidators=[], excludeOtherData=true, ...rest}) => {
    const {enqueueSnackbar} = useSnackbar();
    const getData = () => {
        const newData = {}
        let formValid = true;
        const newErrors = {...errors}
        for (const fieldName in validators) {
            const value = data[fieldName]
            for (let validator of validators[fieldName]) {
                const {valid, error} = validator(value)
                if (!valid) {
                    newErrors[fieldName] = error
                    formValid = false
                }
            }
            newData[fieldName] = data[fieldName]
        }
        for (let customValidator of customValidators)  {
            const {valid, error} = customValidator(data)
            if (!valid) {
                enqueueSnackbar(error, {variant: 'error'})
                formValid = false
                break
            }
        }
        setErrors(newErrors)

        if (formValid) return excludeOtherData ? newData : data
        else return null
    }

    useImperativeHandle(reference, () => ({
        getData
    }))

    return <form {...rest}>
        {children}
    </form>
}

export default ErrorHandlingForm;