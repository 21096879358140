import {Stack} from "@mui/material";
import {useState} from "react";
import CustomSwitch from "../../../components/formValidation/CustomSwitch";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";

const Visibility = ({reference, data, setData}) => {

    const [errors, setErrors] = useState({
        is_public: false,
    });

    const validators = {
        is_public: [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='is_public'
                labelOff='Rejtett'
                labelOn='Látható'/>
        </Stack>
    </ErrorHandlingForm>
}

export default Visibility