import Typography from "@mui/material/Typography";
import {Button, Stack, TextField} from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DinoDialog from "../../../components/Dialog";
import {useState, useContext} from "react";
import useCallDataApi from "../../../hooks/data";
import AuthContext from "../../../context/AuthContext";

const DeleteAccount = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const {postData} = useCallDataApi('colleague')
    const {logoutUser} = useContext(AuthContext)

    const [bye, setBye] = useState('')

    const deleteAccount = () => {
        postData('delete_user/', {})
            .then(r => logoutUser())
            .catch(() => alert('Valami nem sikerült!'))
    }

    const foreverDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} disabled={bye.toUpperCase() !== 'TÖRLÉS'} onClick={() => {
            setDeleteDialogOpen(false)
            deleteAccount()
        }} endIcon={<DeleteForeverRoundedIcon/>}>Fiók törlése</Button>
    </>

    return <div className="white-card">
        <Typography variant='subtitle2'>Fiók törlése</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>
            Ha admin felhasználóként kitörli a
            fiókot minden adat elvész.
        </Typography>
        <Button
            onClick={() => setDeleteDialogOpen(true)}
            endIcon={<DeleteForeverRoundedIcon/>}>
            Fiók törlése
        </Button>

        <DinoDialog
            open={deleteDialogOpen}
            handleClose={() => setDeleteDialogOpen(false)}
            title='Fiók törlése'
            subtitle='Ha admin felhasználóként törli a fiókot minden adat elvész!'
            actions={foreverDeleteActions}>
            <Stack spacing={1}>
                <Typography variant='caption'>Ha biztosan törölni szeretné gépelje be a "törlés" szót</Typography>
                <TextField variant='standard' value={bye} onChange={e => setBye(e.target.value)}/>
            </Stack>
        </DinoDialog>
    </div>
}

export default DeleteAccount