import Footer from "../components/Footer";
import ResponsiveDrawer from "../components/AuthDrawer";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {useContext, useEffect} from "react";
import AuthContext from "../context/AuthContext";


const AuthBase = ({children, label, buttons, filterButtons, filterButtonsMobile}) => {
    return <>
        <ResponsiveDrawer label={label} buttons={buttons} filterButtons={filterButtons} filterButtonsMobile={filterButtonsMobile}>
            <div className="base-container">
                {children}
            </div>
            <Footer/>
        </ResponsiveDrawer>
    </>
}

export default AuthBase