import {InputAdornment, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import contact from '../../../assets/Contact section.png'
import {validateMaxLength, validateRequired} from "../../../components/formValidation/validators";

const Contact = ({reference, disabled, data, setData}) => {

    const [errors, setErrors] = useState({
        contact_heading: false,
        contact_text: false,
        facebook: false,
        instagram: false,
        twitter: false,
        youtube: false,
    });

    const validators = {
        contact_heading: [validateRequired, value => validateMaxLength(255, value)],
        contact_text: [validateRequired, ],
        facebook: [],
        instagram: [],
        twitter: [],
        youtube: [],
    }
    

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '50%' // Half width in row direction
                },
            },
        }}>
            <Stack spacing={1}>
                <Typography variant='caption' color='primary.main'>Címsor</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='contact_heading'
                    variant='standard'
                    disabled={disabled}
                />
                <Typography variant='caption' color='primary.main'>Alcím</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='contact_text'
                    variant='standard'
                    disabled={disabled}
                    multiline
                    rows={4}
                />
                <Typography variant='caption' color='primary.main'>Facebook</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='facebook'
                    variant='standard'
                    disabled={disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">https://facebook.com/</InputAdornment>
                    }}
                />
                <Typography variant='caption' color='primary.main'>Instagram</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='instagram'
                    variant='standard'
                    disabled={disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">https://instagram.com/</InputAdornment>
                    }}
                />
                <Typography variant='caption' color='primary.main'>Twitter</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='twitter'
                    variant='standard'
                    disabled={disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">https://twitter.com/</InputAdornment>
                    }}
                />
                <Typography variant='caption' color='primary.main'>YouTube</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='youtube'
                    variant='standard'
                    disabled={disabled}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">https://youtube.com/</InputAdornment>
                    }}
                />
            </Stack>
            <img src={contact} alt="kontakt" style={{width: '100%'}}/>
        </Stack>
    </ErrorHandlingForm>
}

export default Contact;