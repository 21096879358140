import Base from "./Base";
import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import IconButton from "@mui/material/IconButton";
import Loading from "../../components/Loading";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {
    validateEmail,
    validatePassword, validatePhoneNumber,
    validateRequired,
    validateSubdomain
} from "../../components/formValidation/validators";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomPhoneField from "../../components/formValidation/CustomPhoneField";


const Register = () => {
    const {registerUser, authLoading} = useContext(AuthContext)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const registerRef = useRef(null)
    const theme = useTheme()
    const [data, setData] = useState({
        public_name: '',
        subdomain: '',
        email: '',
        last_name: '',
        first_name: '',
        password: '',
        password_again: '',
        phone_number: '+36',
    })
    const [errors, setErrors] = useState({
        public_name: false,
        subdomain: false,
        email: false,
        last_name: false,
        first_name: false,
        password: false,
        password_again: false,
        phone_number: false,
    })

    const validators = {
        public_name: [validateRequired],
        subdomain: [validateRequired, validateSubdomain],
        email: [validateRequired, validateEmail],
        last_name: [validateRequired],
        first_name: [validateRequired],
        password: [validateRequired, validatePassword],
        password_again: [validateRequired, validatePassword],
        phone_number: [validateRequired, validatePhoneNumber],
    }

    const validatePasswordMatch = data => {
        const isPasswordSet = !!data?.password
        const match = data?.password === data?.password_again
        return {
            valid: isPasswordSet && match,
            error: 'A két jelszó nem egyezik meg!'
        }
    }

    const handleRegister = () => {
        const data = registerRef.current.getData()
        if (data) registerUser(data)
    }

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <Stack spacing={{xs: 2}}>
                    <Typography variant="headline6" color='primary.600'>Regisztráció</Typography>
                    <ErrorHandlingForm
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        reference={registerRef}
                        customValidators={[validatePasswordMatch]}
                    >
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color='primary.main'>Vállalkozás neve*</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="public_name" variant='standard'/>
                            <Typography variant="caption" color='primary.main'>Időpontfoglaló új webcíme (Ezen a címen
                                lesz elérhető a saját foglaló oldalad!)*</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="subdomain"
                                variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end">.dinobooking.hu</InputAdornment>
                            }}/>
                            <Typography variant="caption" color='primary.main'>Email*</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="email"
                                variant='standard'/>
                            <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                                <Stack spacing={1} sx={{width: '100%'}}>
                                    <Typography variant="caption" color='primary.main'>Vezetéknév</Typography>
                                    <CustomTextField
                                        data={data}
                                        setData={setData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        name="last_name" variant='standard'/>
                                </Stack>
                                <Stack spacing={1} sx={{width: '100%'}}>
                                    <Typography variant="caption" color='primary.main'>Keresztnév</Typography>
                                    <CustomTextField
                                        data={data}
                                        setData={setData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        name="first_name"
                                        variant='standard'/>
                                </Stack>
                            </Stack>
                            <Stack spacing={1} sx={{width: '100%'}}>
                                <Typography variant="caption" color='primary.main'>Telefonszám</Typography>
                                <CustomPhoneField
                                    variant='standard'
                                    data={data}
                                    setData={setData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    name='phone_number'
                                    defaultCountry='hu'
                                />
                            </Stack>
                            <Typography variant="caption" color='primary.main'>Jelszó</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                variant='standard'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        edge="end"
                                    >
                                        {passwordVisible ? <VisibilityOffRoundedIcon/> : <RemoveRedEyeRoundedIcon/>}
                                    </IconButton>
                                    </InputAdornment>
                                }}/>
                            <Typography variant="caption" color='primary.main'>Jelszó mégegyszer</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="password_again"
                                type={passwordVisible ? "text" : "password"}
                                variant='standard'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            edge="end"
                                        >
                                            {passwordVisible ? <VisibilityOffRoundedIcon/> : <RemoveRedEyeRoundedIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                }}/>
                        </Stack>
                    </ErrorHandlingForm>
                    <Link to="/forgot-password" style={{color: theme.palette.primary[600]}}>Elfelejtetted a jelszavad?</Link>
                    <Button variant="contained" onClick={handleRegister}>Regisztráció</Button>
                    <Link to="/login" style={{color: theme.palette.primary[600]}}>Van már felhasználói fiókod? Jelentkezz be!</Link>
                </Stack>

            </div>
        </div>
        <Loading isLoading={authLoading}/>
    </Base>
}

export default Register