const serviceTemplate = {
    business: 1,
    description: "",
    id: 1,
    image: "",
    price: 0,
    name: "",
    is_public: false,
    vat_percent: 27,
}

export default serviceTemplate