import AuthBase from "../AuthBase";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import DinoDataGrid from "../../components/DataGrid";
import Typography from "@mui/material/Typography";
import thousandSeparator from "../../utils/numberUtils";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {Avatar, Stack} from "@mui/material";
import DetailDrawer from "../../components/DetailDrawer";
import OrderDetail from "./OrderDetail";
import DinoDialog from "../../components/Dialog";
import Button from "@mui/material/Button";
import BasicDetail from "./BasicDetail";
import GeneralStepper from "../../components/GeneralStepper";
import GeneralData from "./newOrder/GeneralData";
import {AddCircleRounded} from "@mui/icons-material";
import SelectProduct from "./newOrder/SelectProduct";
import BillingFormTabs from "./newOrder/BillingTabs";
import {useSnackbar} from "notistack";

const Orders = () => {
    const {getData: fetchOrder, deleteData, createData: createOrder, getBlob: fetchBlob} = useCallDataApi('order')
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [current, setCurrent] = useState(-1)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const initialData = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '+36',
        comment: '',
    };
    const [data, setData] = useState(initialData)
    const generalRef = useRef(null)
    const personBillingRef = useRef(null)
    const companyBillingRef = useRef(null)
    const {enqueueSnackbar} = useSnackbar()

    const validateGeneral = () => {
        const data = generalRef.current.getData()
        return {
            valid: !!data,
            error: 'A továbblépéshez, javítsd a hibát!'
        }
    }

    const validateBilling = () => {
        const personData = personBillingRef.current?.getData()
        const companyData = companyBillingRef.current?.getData()
        return {
            valid: personData || companyData,
            error: 'A továbblépéshez, javítsd a hibát!'
        }
    }

    const validateProducts = () => {
        return {
            valid: !!data?.ordered_products && data?.ordered_products?.length > 0,
            error: 'A továbblépéshez válassz legalább egy terméket!'
        }
    }

    const placeOrder = () => {
        setNewOpen(false)
        setLoading(true)
        setData(initialData)
        createOrder(data)
            .then(o => setOrders([...orders, o]))
            .finally(() => setLoading(false))
    }

    const findOrder = id => orders.find(o => o?.id === id)

    const deleteOrder = id => {
        setLoading(true)
        deleteData(id)
            .then(() => setOrders(orders.filter(o => o?.id !== id)))
            .finally(() => setLoading(false))
    }

    const createInvoice = id => {
        setLoading(true)
        fetchOrder(`${id}/create_invoice`)
            .then(r => setOrders(orders.map(order =>
                order.id === id ? {...order, ...r} : order
            )))
            .finally(() => setLoading(false))
    }

    const downloadInvoice = id => {
        fetchBlob(`${id}/download_invoice/`)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
        setDrawerOpen(false)
    }

    const sendInvoice = id => {
        setLoading(true)
        fetchOrder(`${id}/send_invoice/`)
            .then(() => {
                enqueueSnackbar('Kiküldve!', {variant: 'success'})
            })
            .finally(() => setLoading(false))
        setDrawerOpen(false)
    }

    useEffect(() => {
        fetchOrder('get_for_business')
            .then(o => setOrders(o))
            .finally(() => setLoading(false))
    }, [])

    const statusMap = {
        'accepted': 'elfogadott',
        'pending': 'elfogadásra vár...',
        'rejected': '',

    }

    const renderName = (params) => <Stack spacing={1} direction='row'>
        <Avatar className={params.row?.status}>{params.row?.last_name[0]}{params.row?.first_name[0]}</Avatar>
        <Stack spacing={0}>
            <span className='reservation-name'>{params.row?.name}</span>
            <span
                className={`reservation-${params.row?.status}`}>{params.row?.status !== 'rejected' && statusMap[params.row?.status]}</span>
        </Stack>
    </Stack>

    const dialogActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            deleteOrder(current)
        }} endIcon={<DeleteIcon/>}>Megrendelés törlése</Button>
    </>

    const columns = [
        {field: 'name', headerName: 'Rendelő neve', width: 200, renderCell: renderName},
        {
            field: 'date',
            headerName: 'Rendelve ekkor',
            type: 'dateTime',
            width: 200,
            valueGetter: (params) => `${params.row?.created_at}`
        },
        {
            field: 'price',
            headerName: 'Ár',
            width: 130,
            type: 'number',
            renderCell: (params) => <Typography variant='button'
                                                style={{color: '#536B74'}}>{thousandSeparator(params.row?.price) + ' Ft'}</Typography>,
            valueGetter: (params) => params.row?.price
        },
        {
            field: 'manage',
            headerName: 'Kezelés',
            sortable: false,
            type: "actions",
            width: 200,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon className='icon-style'/>}
                    onClick={() => {
                        setCurrent(params?.row?.id)
                        setDeleteOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={false}/>,
                <GridActionsCellItem
                    icon={<KeyboardArrowRightRoundedIcon className='inverted-icon-style'/>}
                    onClick={() => {
                        setCurrent(params?.row?.id)
                        setDrawerOpen(true)
                    }}
                    label="Részletek"
                    showInMenu={false}/>]
        },
    ]

    const buttons = [{
        name: 'Új megrendelés',
        props: {
            variant: 'contained',
            endIcon: <AddCircleRounded/>,
            onClick: () => setNewOpen(true)
        }
    }]

    return <AuthBase label='Megrendelések' buttons={buttons}>
        <div className="main">
            <DinoDataGrid columns={columns} rows={orders}/>
        </div>

        <DetailDrawer open={drawerOpen} setOpen={setDrawerOpen}>
            <OrderDetail order={findOrder(current)} close={() => setDrawerOpen(false)} createInvoice={createInvoice}
                         downloadInvoice={downloadInvoice} sendInvoice={sendInvoice}/>
        </DetailDrawer>

        <DinoDialog title='Biztos törölni szeretnéd az alábbi megrendelést?'
                    subtitle='Csak abban az esetben törölj megrendelést ha  azt a klienssel előre leegyeztetted!'
                    open={deleteOpen}
                    handleClose={() => setDeleteOpen(false)}
                    actions={dialogActions}>
            <BasicDetail order={findOrder(current)}/>
        </DinoDialog>

        <DinoDialog title='Új megrendelés'
                    subtitle='Add meg a megrendelés adatait'
                    open={newOpen}
                    handleClose={() => setNewOpen(false)}
            // actions={dialogActions}
        >
            <GeneralStepper steps={[
                {
                    label: 'Termékek',
                    component: <SelectProduct data={data} setData={setData}/>,
                    validate: validateProducts
                },
                {
                    label: 'Személyes adatok',
                    component: <GeneralData data={data} setData={setData} reference={generalRef}/>,
                    validate: validateGeneral
                },
                {
                    label: 'Számlázási adatok',
                    component: <BillingFormTabs data={data} setData={setData} personReference={personBillingRef}
                                                companyReference={companyBillingRef}/>,
                    validate: validateBilling
                }
            ]} onFinish={placeOrder}/>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Orders