import AuthBase from "../../AuthBase";
import {useContext, useEffect, useRef, useState} from "react";
import {Prompt, useHistory, useParams} from "react-router-dom";
import useCallDataApi from "../../../hooks/data";
import Masonry from '@mui/lab/Masonry';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import serviceTemplate from "./ServiceTemplate";
import Loading from "../../../components/Loading";
import businessTemplate from "../../website/BusinessTemplate";
import AuthConfigContext from "../../../context/AuthConfigContext";
import General from "./components/General";
import Visibility from "./components/Visibility";
import Image from "./components/Image";
import Category from "./components/Category";
import Invoice from "./components/Invoice";
import Payment from "./components/Payment";
import MaxDate from "./components/MaxDate";
import {useSnackbar} from "notistack";
import {mergeDataIfNotNull} from "../../../utils/misc";
import Questions from "./components/Questions";
import Colleagues from "./components/Colleagues";

const ServiceDetail = () => {
    const history = useHistory()

    const {billingAvailable} = useContext(AuthConfigContext)
    const {enqueueSnackbar} = useSnackbar()

    const [business, setBusiness] = useState(businessTemplate)
    const [service, setService] = useState(serviceTemplate)
    const [editedService, setEditedService] = useState(serviceTemplate)
    const [loading, setLoading] = useState(true)

    const params = useParams()

    const generalRef = useRef(null)
    const visibilityRef = useRef(null)
    const imageRef = useRef(null)
    const categoryRef = useRef(null)
    const invoiceRef = useRef(null)
    const paymentRef = useRef(null)
    const maxDatesRef = useRef(null)

    const {getData: fetchService, updateDataFormData: updateService} = useCallDataApi('service')
    const {getData: fetchBusiness} = useCallDataApi('business')

    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchService(params.id),
            fetchBusiness('get_business/'),
        ])
            .then(([s, b]) => {
                setService(s)
                setEditedService(s)
                setBusiness(b)
            })
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasUnsavedChanges = JSON.stringify(editedService) !== JSON.stringify(service);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault()
                event.returnValue = "" // Required for modern browsers
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);

    const savePageData = () => {
        const refData = [
            generalRef.current.getData(),
            visibilityRef.current.getData(),
            imageRef.current.getData(),
            categoryRef.current.getData(),
            invoiceRef.current.getData(),
            paymentRef.current.getData(),
            maxDatesRef.current.getData(),
        ]
        const invalidRefs = refData.filter(ref => ref === null)
        if (invalidRefs.length === 0) {
            const allData = mergeDataIfNotNull(refData);
            const isFile = allData?.image instanceof File;
            if (!isFile) delete allData['image']
            setLoading(true)
            updateService(service?.id, allData).then(s => {
                setService(s)
                setEditedService(s)
                enqueueSnackbar('Mentve!', {variant: 'success'})
            }).finally(() => setLoading(false))
        } else {
            enqueueSnackbar('Kérlek javísd a hibákat!', {variant: 'error'})
        }
    }

    const copyBookingLink = () => {
        navigator.clipboard.writeText(`https://${business?.subdomain}.foglalapp.hu/book?c=${service.category}&s=${service.id}`)
            .then(() => enqueueSnackbar('Másolva', {variant: 'success'}))
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            variant: 'contained',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }, {
        name: 'Foglalási link másolása',
        props: {
            variant: 'outlined',
            endIcon: <ContentCopyRoundedIcon/>,
            onClick: copyBookingLink
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }, {
        name: 'link',
        props: {
            variant: 'outlined',
            size: 'small',
            endIcon: <ContentCopyRoundedIcon/>,
            onClick: copyBookingLink
        }
    }]

    return <AuthBase label={service?.name} buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Button
                variant='text'
                startIcon={<ArrowBackIosNewRoundedIcon/>}
                onClick={() => history.push(`/service/${service?.category}`)}>
                vissza
            </Button>

            <Masonry columns={{xs: 1, sm: 1, md: 2, xl: 2}} spacing={2} sx={{margin: 0}}>

                {/*General data*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Általános adatok</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Add meg a szolgáltatás nevét és leírását!
                    </Typography>
                    <General reference={generalRef} data={editedService} setData={setEditedService}/>
                </div>

                {/*Visibility*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Láthatóság</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Ha nem szeretnéd, hogy a szolgáltatás látható legyen
                        a kliensek számára akkor kikapcsolhatod. (Az elrejtés előtt leadott foglalások nem fognak
                        törlődni!)
                    </Typography>
                    <Visibility reference={visibilityRef} data={editedService} setData={setEditedService}/>
                </div>

                {/*Picture*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatás képe</Typography>
                    <Typography variant='body2' color='#536B74'>Tölts fel egy szolgáltatásra jellemző
                        képet!</Typography>
                    <Image reference={imageRef} data={editedService} setData={setEditedService}/>
                </div>

                {/*Category*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatás kategória</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Válaszd ki melyik kategóriába tartozik a szolgáltatás!
                    </Typography>
                    <Category reference={categoryRef} data={editedService} setData={setEditedService}/>
                </div>


                {/*Invoice*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Számlázási beállítások</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Válaszd ki hogyan szeretnél számlázni a vevőid felé!
                    </Typography>
                    <Invoice
                        reference={invoiceRef}
                        data={editedService} setData={setEditedService}
                        paymentAuthorized={business?.payment_authorized} billingAvailable={billingAvailable()}/>
                </div>

                {/*Billing methods*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Fizetési lehetőségek</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Állítsd be milyen fizetési lehtőségek lehetségesek a szolgáltatásnál!
                    </Typography>
                    <Payment
                        reference={paymentRef}
                        data={editedService} setData={setEditedService}
                        paymentAuthorized={business?.payment_authorized}/>
                </div>

                {/*Max dates*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Foglalások elfogadása</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Állítsd be meddig szeretnél előre foglalásokat elfogadni a szolgáltatásnál!
                    </Typography>
                    <MaxDate
                        reference={maxDatesRef}
                        data={editedService} setData={setEditedService}
                    />
                </div>

                {/*Colleagues*/}
                <Colleagues/>

                {/*Questions*/}
                <Questions/>

            </Masonry>
        </div>

        <Loading isLoading={loading}/>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />

    </AuthBase>

}

export default ServiceDetail