import {Stack} from "@mui/material";
import {useContext, useState} from "react";
import ConfigContext from "../../../context/ConfigContext";
import {validateMaxFileSize} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomImageField from "../../../components/formValidation/CustomImageField";



const ProfilePicture = ({reference, data, setData}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const [errors, setErrors] = useState({
        profile_picture: false,
    });

    const validators = {
        profile_picture: [file => validateMaxFileSize(file, MAX_IMG_SIZE)],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomImageField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='profile_picture'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default ProfilePicture