import {Box, FormControlLabel, Grid, Radio} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomRadioGroup from "../../../components/formValidation/CustomRadioGroup";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {validateOneOf} from "../../../components/formValidation/validators";

const Mode = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        usage_type: false,
    });

    const validators = {
        usage_type: [value => validateOneOf(value, ['only_booking', 'website', 'website_webshop'])],
    }

    return <ErrorHandlingForm  data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Box className="radio-selector" marginTop={2} marginBottom={2} >
            <CustomRadioGroup
                aria-labelledby="radio-buttons-group-label"
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='usage_type'
                variant='standard'
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={data?.usage_type === 'website' && 'active-radio'}>
                            <FormControlLabel
                                value='website'
                                control={<Radio/>}
                                label="Teljes weboldalt szeretnék"
                            />
                            <Typography color='#536B74' variant='body2'>
                                Ha ezt az opciót választod akkor
                                egy működő weboldalt
                                kapsz ahova feltöltheted a vállalkozásodra jellemző adatokat és könnyen
                                kapcsolatba léphetsz ügyfeleiddel.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={data?.usage_type === 'only_booking' && 'active-radio'}>
                            <FormControlLabel
                                value='only_booking'
                                control={<Radio/>}
                                label="Csak foglalási felületet szeretnék"
                            />
                            <Typography color='#536B74' variant='body2'>
                                Ha ezt az opciót választod akkor
                                csak egy foglalási
                                felületet kapsz, aminek a linkjét beágyazhatod jelenlegi weboldaladba, így a
                                DinoBookingon keresztül kezelheted a foglalásaidat.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={data?.usage_type === 'website_webshop' && 'active-radio'}>
                            <FormControlLabel
                                value='website_webshop'
                                control={<Radio/>}
                                label="Teljes weboldalt és webshopot szeretnék"
                            />
                            <Typography color='#536B74' variant='body2'>
                                Ha ezt az opciót választod akkor
                                egy foglalási
                                felületet és egy webshopot kapsz, így a
                                DinoBookingon keresztül kezelheted a foglalásaidat és vásárlásaidat.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CustomRadioGroup>
        </Box>
    </ErrorHandlingForm>
}

export default Mode