const components = {
    MuiStepConnector: {
        styleOverrides: {
            root: {
                '&.Mui-disabled': {
                    '.MuiStepConnector-line': {
                        border: '3px solid #DFF1E7',
                        borderRadius: '4px',
                    },
                },
                '&.Mui-active': {
                    '.MuiStepConnector-line': {
                        border: '3px solid',
                        borderRadius: '4px',
                    },
                },
                '&.Mui-completed': {
                    '.MuiStepConnector-line': {
                        border: '3px solid',
                        borderRadius: '4px',
                    },
                },
            },
        },
    },
    MuiIconButton: {
        variants: [
            {
                props: { variant: 'dino' },
                style: {
                    color: '#7EC69D',
                    border: '2px solid',
                },
            },
        ],
    },
    MuiStepLabel: {
        styleOverrides: {
            label: {
                fontFamily: 'Archivo, sans-serif', // Change the font for MuiStepLabel
                fontWeight: '700'
            },
            root: {
                '&.Mui-disabled': {
                    '.MuiSvgIcon-root': {
                        color: '#DFF1E7',

                        '.MuiStepIcon-text': {
                            color: '#fff',
                        }
                    }
                },
            }
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                width: 36, // Set the width of the circle
                height: 36, // Set the height of the circle
            },
            // text: {
            //     fill: 'white', // Change the color of the number inside the circle
            // },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 30,
            }
        },
        variants: [
            {
                props: {variant: 'contained'},
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: '#5EB885',
                    color: '#fff',
                    padding: '12px 16px',
                    gap: '10px',
                    '&:hover': {
                        background: '#7EC69D',
                    },
                    '&:active': {
                        background: '#478A64'
                    }
                },
            }, {
                props: {variant: 'outlined'},
                style: {
                    textTransform: 'none',
                    border: '2px solid #5EB885',
                    background: 'transparent',
                    color: '#5EB885',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: '#EFF8F3',
                        border: '2px solid #5EB885',
                    },
                    '&:active': {
                        background: '#DFF1E7',
                        border: '2px solid #5EB885',
                    }
                },
            }, {
                props: {variant: 'text'},
                style: {
                    textTransform: 'none',
                    border: 'none',
                    background: 'transparent',
                    color: '#5EB885',
                    padding: '12px',
                    gap: '8px',
                    '&:hover': {
                        background: '#EFF8F3',
                    },
                    '&:active': {
                        background: '#DFF1E7',
                    }
                },
            },
        ],
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 20,
                backgroundColor: '#F3F5F7',
                transition: 'background .4s ease'
            }
        }
    },
    MuiInput: {
        styleOverrides: {
            root: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
                '&:hover': {
                    content: 'none',
                },
                '&.Mui-error': {
                    background: '#ffbaba',
                    transition: 'background .4s ease'
                },
            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                // Add your font definition for helper text here
                fontFamily: 'Archivo, sans-serif',
                textTransform: 'none',
                paddingLeft: 10
                // fontSize: 14,
                // color: '#616161', // Choose your desired color
            },
        },
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                border: '1px solid #F3F3F1',
                background: '#fff',
                borderRadius: '16px'
            },
            cell: {
                border: 'none'
            },
        }
    },
    MuiCalendarPicker: {
        styleOverrides: {
            root: {
                border: '1px solid #EFF8F3',
                borderRadius: '16px'
            },
        },
    },
}

export {components}