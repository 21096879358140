import Typography from "@mui/material/Typography";
import thousandSeparator from "../../utils/numberUtils";

const BasicDetail = ({ order }) => {

    return (
        <>
            <Typography variant='subtitle1' color='primary.600'>
                Megrendelés adatai
            </Typography>
            <table className="reservation-detail-table" style={{ width: '100%' }}>
                <tbody>
                <tr>
                    <td><Typography variant='subtitle2'>Megrendelő neve</Typography></td>
                    <td><Typography variant='subtitle2' color='primary.600'>{order?.name}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant='subtitle2'>Telefonszám</Typography></td>
                    <td><Typography variant='subtitle2' color='primary.600'>{order?.phone}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant='subtitle2'>Email cím</Typography></td>
                    <td><Typography variant='subtitle2' color='primary.600'>{order?.email}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant='subtitle2'>Ár</Typography></td>
                    <td><Typography variant='subtitle2' color='primary.600'>{order?.price ? `${thousandSeparator(order.price)} Ft` : '-'}</Typography></td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default BasicDetail;
