import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {useState} from "react";
import {
    validateDividableBy,
    validateMaxLength, validateMaxValue, validateMinValue,
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../../components/formValidation/CustomTextField";
import CustomSwitch from "../../../../components/formValidation/CustomSwitch";

const General = ({reference, data, setData}) => {
    
    const [errors, setErrors] = useState({
        name: false,
        description: false,
        length: false,
        is_accept_automatically: false,
        cancellable: false,
    });

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        description: [validateRequired,],
        length: [validateRequired, validateNumber, validateNonNegativeNumber, value => validateMinValue(value, 5), value => validateMaxValue(value, 1440), value => validateDividableBy(value, 5)],
        is_accept_automatically: [],
        cancellable: [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Szolgáltatás neve (max. 255 karakter)*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Szolgáltatás rövid leírása
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='description'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Szolgáltatás időtartama (perc)*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='length'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                foglalások elfogadása
            </Typography>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='is_accept_automatically'
                labelOff='Manuális'
                labelOn='Automatikus'/>

            <Typography variant='caption' color='primary.main'>Lemondás</Typography>
            <Typography variant='body2' color='#536B74'>
                Amennyiben lemondhatóra van állítva, a kliensek egy
                lemondási linket kapnak az e-mail értesítésekben.
            </Typography>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='cancellable'
                labelOff='Nem lemondható'
                labelOn='Lemondható'/>
        </Stack>
    </ErrorHandlingForm>
}

export default General