

const isUnderMaxFileSize = (file, size) => {
    console.log('File size: ', file.size)
    console.log('Max size: ', size * 1000 * 1000)
    return file.size <= size * 1000 * 1000
}

const createObj = (key, val) => {
    return {[key]: val}
}

const validate = (object, schema) => {
    const keys = Object.keys(schema)
    const result = []
    for (let key of keys) {
        if (!validateField(key, schema, object[key])) {
            result.push(createObj(key, fieldError(key, schema, object[key])))
        }
    }
    return result
}

const isValid = (object, schema) => {
    return validate(object, schema).length === 0
}

const validateField = (field, schema, value) => {
    return schema[field](value)?.every(e => e.result)
}

const fieldError = (field, schema, value) => {
    for (let check of schema[field](value)) {
        if (!check.result) {
            return check.error
        }
    }
    return null
}


const isBoolean = (value) => {
    return {result: typeof value == "boolean", error: 'Nem megfelelő formátum (igen/nem)'}
}

const isNumber = (value) => {
    return {result: typeof value == "number", error: 'Nem megfelelő formátum (szám)'}
}

const hasValue = (value) => {
    return {result: !(value === '' || value === null || value === undefined || value === 'NaN'), error: 'Nem maradhat üresen'}
}

const isGreater = (value, number) => {
    return {result: value > number, error: 'Nagyobbnak kell lennie mint' + number}
}

const tooLong = (value, maxLength) => {
    return {result: value?.length ? value.length : 0 < maxLength, error: 'Túl hosszú, maximum ' + maxLength + ' karakter'}
}

const isDividable = (value, number) => {
    return {result: value % number === 0, error: 'Oszthatónak kell lennie ' + number + '-el/al'}
}

const passAnyway = (value) => {
    return {result: true}
}


export {isValid, validate, validateField, fieldError, isNumber, isBoolean, hasValue, tooLong, isDividable, passAnyway, isGreater, isUnderMaxFileSize}
