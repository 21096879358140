import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import PersonBillingData from "./PersonBillingData";
import CompanyBillingData from "./CompanyBillingData";

const BillingFormTabs = ({ personReference, companyReference, data, setData }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Magánszemély" />
                <Tab label="Cég" />
            </Tabs>
            <Box sx={{ mt: 3 }}>
                {selectedTab === 0 && (
                    <PersonBillingData
                        reference={personReference}
                        data={data}
                        setData={setData}
                    />
                )}
                {selectedTab === 1 && (
                    <CompanyBillingData
                        reference={companyReference}
                        data={data}
                        setData={setData}
                    />
                )}
            </Box>
        </Box>
    );
};

export default BillingFormTabs;
