const getQueryParams = () => {
    const query = window.location.search.replace('?', '').split('&');
    let queryDict = {}
    for (let q of query) {
        let kv = q.split('=')
        queryDict[kv[0]] = kv[1]
    }
    return queryDict
}

const downloadFile = (url, filename) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute to specify the filename
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
}

const getImageUrl = (img, baseUrl) => {
    if (img instanceof File) return URL.createObjectURL(img)
    if (img?.startsWith('http')) return img
    if (img) return baseUrl + img;
    else return ''
}

export {getQueryParams, downloadFile, getImageUrl}