import React from "react";
import Tooltip from "@mui/material/Tooltip";


const DisabledWrapper = ({ disabledCause, spanStyle={}, children }) => {
    // Determine if the child should be disabled
    const activeCause = disabledCause.find((cause) => cause.disabled);

    // Clone the child and inject the disabled prop
    const clonedChild = React.cloneElement(children, {
        disabled: !!activeCause, // Ensure boolean value
    });

    return (
        <Tooltip
            title={activeCause ? activeCause.message : ""}
            disableHoverListener={!activeCause} // Disable tooltip if no cause
        >
            <div style={spanStyle}>{clonedChild}</div>
        </Tooltip>
    );
};

export default DisabledWrapper;
