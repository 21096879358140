import {MenuItem, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {validateRequired} from "../../../../components/formValidation/validators";
import CustomTextField from "../../../../components/formValidation/CustomTextField";
import CustomSelect from "../../../../components/formValidation/CustomSelect";
import CustomSwitch from "../../../../components/formValidation/CustomSwitch";

const QuestionData = ({reference, obj}) => {
    const [data, setData] = useState({
        question: obj?.question || '',
        answer_type: obj?.answer_type || '1',
        required: obj?.required,
    });

    const [errors, setErrors] = useState({
        question: false,
        answer_type: false,
        required: false,
    });

    const validators = {
        question: [validateRequired],
        answer_type: [validateRequired],
        required: [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>Kérdés*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='question'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>Válasz típusa*</Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='answer_type'
                variant='standard'
            >
                <MenuItem value='1'>Szabad szöveges válasz</MenuItem>
                <MenuItem value='2'>Szám válasz</MenuItem>
                <MenuItem value='3'>Igen/nem</MenuItem>
            </CustomSelect>

            <Typography variant='caption' color='primary.main'>kötelező kitölteni a klienseknek</Typography>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='required'
                labelOff='Nem kötelező'
                labelOn='Kötelező'
            />
        </Stack>

    </ErrorHandlingForm>

}

export default QuestionData