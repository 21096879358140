import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import { useState } from "react";
import { validateRequired } from "../../../components/formValidation/validators";
import { Stack, Typography } from "@mui/material";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomCountrySelect from "../../../components/formValidation/CustomCountrySelect";

const PersonBilling = ({ reference, data, setData }) => {
    const [errors, setErrors] = useState({
        name: false,
        country: false,
        postal_code: false,
        state: false,
        city: false,
        address: false,
    });

    const validators = {
        name: [validateRequired],
        country: [validateRequired],
        postal_code: [validateRequired],
        state: [validateRequired],
        city: [validateRequired],
        address: [validateRequired],
    };

    return (
        <ErrorHandlingForm
            reference={reference}
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
            validators={validators}
        >
            <Stack spacing={1}>
                <Typography id="billing-name-label" variant="caption" color='primary.main'>
                    Számlázási név*
                </Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="name"
                    variant="standard"
                    inputProps={{
                        "aria-labelledby": "billing-name-label",
                        "aria-required": "true",
                    }}
                />

                <Typography id="billing-country-label" variant="caption" color='primary.main'>
                    Ország*
                </Typography>
                <CustomCountrySelect
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="country"
                    variant="standard"
                    inputProps={{
                        "aria-labelledby": "billing-country-label",
                        "aria-required": "true",
                    }}
                />

                <Typography id="billing-state-label" variant="caption" color='primary.main'>
                    Megye*
                </Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="state"
                    variant="standard"
                    inputProps={{
                        "aria-labelledby": "billing-state-label",
                        "aria-required": "true",
                    }}
                />

                <Stack direction={{xs: 'column', md: 'row'}} width='100%' spacing={2}>
                    <Stack width='100%' spacing={1}>
                        <Typography id="billing-postal-code-label" variant="caption" color='primary.main'>
                            Irányítószám*
                        </Typography>
                        <CustomTextField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="postal_code"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "billing-postal-code-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack>

                    <Stack width='100%' spacing={1}>
                        <Typography id="billing-city-label" variant="caption" color='primary.main'>
                            Város*
                        </Typography>
                        <CustomTextField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="city"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "billing-city-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack>
                </Stack>

                <Typography id="billing-address-label" variant="caption" color='primary.main'>
                    Cím*
                </Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="address"
                    variant="standard"
                    inputProps={{
                        "aria-labelledby": "billing-address-label",
                        "aria-required": "true",
                    }}
                />
            </Stack>
        </ErrorHandlingForm>
    );
};

export default PersonBilling;
