import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import {useSnackbar} from "notistack";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [authLoading, setAuthLoading] = useState(false)
    const history = useHistory();
    const snackbar = useSnackbar()

    const api = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const getUser = () => {
        if (!authTokens?.access) return null
        return jwt_decode(authTokens?.access)
    }

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        localStorage.setItem("authTokens", JSON.stringify(data));
    }

    const setAuthStorageGoogle = (data) => {
        setAuthTokens({access: data?.access, refresh: data?.refresh});
        localStorage.setItem("authTokens", JSON.stringify({access: data?.access, refresh: data?.refresh}));
    }

    const loginUser = (data) => {
        api.post('api/token/', JSON.stringify(data))
            .then(r => {
                const data = r?.data
                setAuthStorage(data)
                history.push("/")
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) snackbar.enqueueSnackbar('Hiba, helytelen email vagy jelszó!', {variant: 'error'})
                else snackbar.enqueueSnackbar('Hiba a bejelentkezésnél, próbálja újra később!', {variant: 'error'})
                console.log(e)
            })
    }

    const loginUserWithGoogle = (data) => {
        api.post('api/o-auth/google/', JSON.stringify(data))
            .then(r => {
                const data = r?.data
                setAuthStorageGoogle(data)
                history.push("/")
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) snackbar.enqueueSnackbar('Hiba, helytelen email vagy jelszó!', {variant: 'error'})
                else snackbar.enqueueSnackbar('Hiba a bejelentkezésnél, próbálja újra később!', {variant: 'error'})
                console.log(e)
            })
    }

    const registerUser = (data) => {
        if (data.password !== data.password_again) {
            snackbar.enqueueSnackbar("Nem egyezik a két jelszó!", {variant: 'error'})
            return
        }
        setAuthLoading(true)
        api.post('register_new/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                history.push('/success')
            })
            .catch(e => {
                setAuthLoading(false)
                const message = e?.response?.data?.message
                if (message) snackbar.enqueueSnackbar(message, {variant: 'error'})
                else snackbar.enqueueSnackbar('Váratlan hiba!', {variant: 'error'})
                console.log(e)
            })
    }

    const registerExistingUser = (data) => {
        if (data?.password !== data?.password_again) {
            snackbar.enqueueSnackbar('Nem egyezik a két jelszó!', {variant: 'error'})
            return
        }
        api.post('register_for_business/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                history.push('/login')
            })
            .catch(e => {
                setAuthLoading(false)
                const message = e?.response?.data?.message
                if (message) snackbar.enqueueSnackbar(message, {variant: 'error'})
                else snackbar.enqueueSnackbar('Váratlan hiba!', {variant: 'error'})
                console.log(e)
            })
    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        localStorage.removeItem("authTokens")
    }, []);

    const updateToken = useCallback(() => {
        return api.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
            .then(r => {
                const data = r?.data
                setAuthStorage(data)
                return data.access
            })
            .catch(e => {
                logoutUser()
                throw e
            })
    }, [authTokens?.refresh, logoutUser])

    const forgotPassword = (data) => {
        setAuthLoading(true)
        api.post('password/send_link/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Email kiküldve!', {variant: 'success'})
                history.push("/")
            })
            .catch(e => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const newPassword = (data) => {
        setAuthLoading(true)
        api.post('password/change_password/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Sikeresen megváltoztatva!', {variant: 'success'})
                history.push("/login")
            })
            .catch(e => {
                setAuthLoading(true)
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const contextData = {
        authTokens: authTokens,
        loginUser: loginUser,
        registerUser: registerUser,
        registerExistingUser: registerExistingUser,
        logoutUser: logoutUser,
        forgotPassword: forgotPassword,
        newPassword: newPassword,
        authLoading: authLoading,
        setAuthLoading: setAuthLoading,
        updateToken: updateToken,
        loginUserWithGoogle: loginUserWithGoogle,
        getUser: getUser
    }

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
