import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {formatTime, getNameOfDay} from "../utils/misc";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import _ from "lodash";
import DinoDialog from "./Dialog";
import WorkDayData from "../pages/colleague/components/WorkDayData";
import {useState} from "react";
import {extractTime} from "../utils/dateUtils";
import {useRef} from "react";
import useCallDataApi from "../hooks/data";

const OnlyOpenAtPicker = ({editedOpeningHours, setEditedOpeningHours, userId, setOpeningHours}) => {
    const [workdayDialog, setWorkdayDialog] = useState(false)
    const [deleteWorkTimeOpen, setDeleteWorkTimeOpen] = useState(false)
    const [current, setCurrent] = useState(-1)
    const workdayRef = useRef(null)

    const {
        updateData: updateOpeningHours,
        postData: postWorkHour
    } = useCallDataApi('work-hour')

    const deleteIconButtonStyle = {
        alignSelf: 'center',
        color: 'primary.main',
        border: '2px solid',
        borderColor: 'primary.main'
    }

    const workdayActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setWorkdayDialog(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}}
                onClick={() => {
                    const data = workdayRef.current.getData()
                    if (data) {
                        const {day, open_from, open_until} = data
                        const newData = {
                            day: day,
                            open_from: extractTime(open_from),
                            open_until: extractTime(open_until),
                        }
                        updateOpeningHours(`${userId}/update_user_only_time/`, newData).then(o => {
                            setOpeningHours(o)
                            setEditedOpeningHours(_.cloneDeep(o))
                        })
                        setWorkdayDialog(false)
                    }
                }} endIcon={<AddCircleRoundedIcon/>}>Hozzáadás</Button>
    </>


    return <>
        <Stack spacing={2}>
            <Typography variant='subtitle1'>Ezekre a napokra adtál meg munkaidőt:</Typography>
            {editedOpeningHours?.open_times?.length === 0 &&
                <Box sx={{
                    border: '1px dashed',
                    borderColor: '#F1F4F5',
                    borderRadius: '16px',
                    marginTop: 2
                }}>
                    <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                        <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek munkaidők</Typography>
                        <Typography variant='body2' color='#6F8E9A'>
                            Adj hozzá új munkaidőt az alábbi gombra kattintva! 👇
                        </Typography>
                    </Stack>
                </Box>}
            {editedOpeningHours?.open_times?.map((ot, idx) => <Stack key={idx} direction='row'
                                                                     spacing={2} alignItems='center'>
                <Box>
                    <Typography variant='subtitle2'>{ot?.day} ({getNameOfDay(ot?.day)})</Typography>
                    <Typography
                        sx={{color: '#536B74'}}
                        variant='body2'>
                        {formatTime(ot?.open_from)} - {formatTime(ot?.open_until)}
                    </Typography>
                </Box>
                <IconButton
                    sx={{...deleteIconButtonStyle, alignSelf: 'center'}}
                    onClick={() => {
                        setCurrent(ot.id)
                        setDeleteWorkTimeOpen(true)
                    }}>
                    <DeleteRoundedIcon/>
                </IconButton>
            </Stack>)}
            <Box>
                <Button
                    variant='contained'
                    size='small'
                    endIcon={<AddCircleRoundedIcon/>}
                    onClick={() => setWorkdayDialog(true)}
                >
                    Új munkaidő hozzáadása
                </Button>
            </Box>
        </Stack>

        <DinoDialog open={workdayDialog}
                    title='Munkaidő megadása'
                    subtitle='Add meg mikor szeretnél dolgozni'
                    handleClose={() => setWorkdayDialog(false)}
                    actions={workdayActions}>
            <WorkDayData reference={workdayRef}/>
        </DinoDialog>

        <DinoDialog open={deleteWorkTimeOpen}
                    title='Munkaidő törlése'
                    subtitle='Biztos vagy benne hogy szeretnéd törölni a munkaidőt?'
                    handleClose={() => setDeleteWorkTimeOpen(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setDeleteWorkTimeOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    postWorkHour('delete_open_only_at', {id: current}).then(o => {
                        setOpeningHours(o)
                        setEditedOpeningHours(_.cloneDeep(o))
                    })
                    setDeleteWorkTimeOpen(false)
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>
    </>
}

export default OnlyOpenAtPicker