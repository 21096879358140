import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateRequired} from "../../../components/formValidation/validators";

const Colors = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        color: false,
    });

    const validators = {
        color: [ validateRequired],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '100%' // Half width in row direction
                },
            },
        }}>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='color'
                variant='standard'
                sx={{display: 'none'}}
            />
            <Stack spacing={2}>
                <Typography color='primary.main' variant='caption'>Színek</Typography>
                <Stack direction={{xs: 'column', sm: 'column', md: 'row'}} spacing={1} marginTop='10px'>
                    <Box onClick={() => setData({...data, color: 'green'})}
                         className={data?.color === 'green' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#5EB885'}}/>
                    <Box onClick={() => setData({...data, color: 'purple'})}
                         className={data?.color === 'purple' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#98658D'}}/>
                    <Box onClick={() => setData({...data, color: 'powder'})}
                         className={data?.color === 'powder' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#BE8C88'}}/>
                    <Box onClick={() => setData({...data, color: 'blue'})}
                         className={data?.color === 'blue' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#3D8AB4'}}/>
                    <Box onClick={() => setData({...data, color: 'grey'})}
                         className={data?.color === 'grey' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#4F5869'}}/>
                </Stack>
            </Stack>
        </Stack>
    </ErrorHandlingForm>
}

export default

Colors;