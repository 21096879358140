import {Select, MenuItem, FormHelperText, FormControl, Box, Typography} from "@mui/material";
import React from "react";
import countries from "../../utils/countries";


const CustomCountrySelect = ({
                                 errors,
                                 setErrors,
                                 data,
                                 setData,
                                 name,
                                 validators,
                                 label,
                                 ...rest
                             }) => {
    const handleChange = (event) => {

        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const handleBlur = (event) => {
        const {name, value} = event.target;
        for (let validator of validators[name]) {
            const {valid, error} = validator(value);
            if (!valid) {
                setErrors({...errors, [name]: error});
                break;
            } else setErrors({...errors, [name]: false});
        }
    };

    return (
        <FormControl fullWidth error={Boolean(errors[name])} {...rest}>
            <Select
                name={name}
                value={data[name] || ""}
                onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected) {
                        return <Typography color="textSecondary">{label}</Typography>;
                    }
                    const selectedCountry = countries.find((country) => country?.code === selected);
                    return (
                        <Box display="flex" alignItems="center">
                            <Box
                                component="img"
                                src={`https://flagcdn.com/w40/${selectedCountry?.code.toLowerCase()}.png`}
                                alt="flag"
                                sx={{marginRight: 1, width: 20, height: 15}}
                            />
                            {selectedCountry?.label}
                        </Box>
                    );
                }}
            >
                <MenuItem value="" disabled>
                    {label}
                </MenuItem>
                {countries.map((country) => (
                    <MenuItem key={country?.code} value={country?.code}>
                        <Box display="flex" alignItems="center">
                            <Box
                                component="img"
                                src={`https://flagcdn.com/w40/${country?.code.toLowerCase()}.png`}
                                alt="flag"
                                sx={{marginRight: 1, width: 20, height: 15}}
                            />
                            {country?.label}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
            {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
        </FormControl>
    );
};

export default CustomCountrySelect;

// Example countries.js file
// export const countries = [
//   { code: "US", label: "United States" },
//   { code: "CA", label: "Canada" },
//   { code: "MX", label: "Mexico" },
// ];
