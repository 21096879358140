import Typography from "@mui/material/Typography";
import {Grid, Stack} from "@mui/material";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomTinyMCE from "../../../components/formValidation/CustomTinyMce";
import {validateMaxLength, validateRequired} from "../../../components/formValidation/validators";

const ResignationOrderColleague = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        colleague_order_resignation_email_title: false,
        colleague_order_resignation_email: false,
        colleague_order_resignation_sms: false
    })

    const validators = {
        colleague_order_resignation_email_title: [validateRequired, value => validateMaxLength(255, value)],
        colleague_order_resignation_email: [validateRequired],
        colleague_order_resignation_sms: [value => validateMaxLength(255, value)]
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        reference={reference}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
    >
        <Grid container spacing={3} marginTop={2}>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    <Typography variant='caption' color='primary.main'>
                        e-mail címsor (max. 255 karakter)
                    </Typography>
                    <CustomTextField
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        name='colleague_order_resignation_email_title'
                        variant='standard'
                    />
                    <Typography variant='caption' color='primary.main'>
                        e-mail szövege
                    </Typography>
                    <CustomTinyMCE
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        name='colleague_order_resignation_email'
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    <Typography variant='caption' color='primary.main'>
                        sms címsor (max. 255 karakter)
                    </Typography>
                    <CustomTextField
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        name='colleague_order_resignation_sms'
                        variant='standard'
                        multiline
                        rows={5}
                    />
                </Stack>
            </Grid>
        </Grid>
    </ErrorHandlingForm>
}

export default ResignationOrderColleague