import { FormControl, FormLabel, RadioGroup } from "@mui/material";

const CustomRadioGroup = ({ errors, setErrors, data, setData, name, validators, options, label, children, ...rest }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        for (let validator of validators[name]) {
            const { valid, error } = validator(value);
            if (!valid) {
                setErrors({ ...errors, [name]: error });
                break;
            } else {
                setErrors({ ...errors, [name]: false });
            }
        }
    };

    return (
        <FormControl error={Boolean(errors[name])} {...rest}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                name={name}
                value={data?.[name]}
                onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
            >
                {children}
            </RadioGroup>
            {errors[name] && <p style={{ color: 'red', marginTop: 4 }}>{errors[name]}</p>}
        </FormControl>
    );
};

export default CustomRadioGroup;
