import Base from "./Base";
import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import {validateEmail, validateRequired} from "../../components/formValidation/validators";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../components/formValidation/CustomTextField";


const ForgotPassword = () => {
    const {forgotPassword} = useContext(AuthContext)
    const theme = useTheme()
    const [errors, setErrors] = useState({
        email: false
    })
    const [data, setData] = useState({
        email: ''
    })
    const validators = {
        email: [validateRequired, validateEmail],
    }
    const formRef = useRef(null)

    const handleForgot = () => {
        const data = formRef.current.getData()
        if (data) forgotPassword(data)
    }


    return <Base>
        <div className="login-container">
            <div className="login-card">
                <ErrorHandlingForm
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    reference={formRef}
                >
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color='primary.600'>Elfelejtett jelszó</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color='primary.main'>E-mail cím</Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="email"
                                variant='standard'
                            />
                        </Stack>
                        <Button variant="contained" onClick={handleForgot}>Email küldése</Button>
                        <Link to="/login" style={{color: theme.palette.primary[600]}}>Bejelentkezés</Link>
                    </Stack>
                </ErrorHandlingForm>
            </div>
        </div>
    </Base>
}

export default ForgotPassword