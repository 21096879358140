import {
    Autocomplete, Checkbox,
    FormControl,
    FormControlLabel, Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import countries from "../../utils/countries";
import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import BusinessTemplate from "../website/BusinessTemplate";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import useCallDataApi from "../../hooks/data";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import WizardBase from "./WizardBase";
import thousandSeparator from "../../utils/numberUtils";
import CompanyBilling from "./components/CompanyBilling";
import PersonBilling from "./components/PersonBilling";

const CardRegistration = () => {
    const [business, setBusiness] = useState(BusinessTemplate)
    const [price, setPrice] = useState({one_price: '-', multiple_price: '-'})
    const [editedBusiness, setEditedBusiness] = useState(BusinessTemplate)
    const [invoiceDataProvided, setInvoiceDataProvided] = useState(false)
    const [aszfAccepted, setAszfAccepted] = useState(false)
    const {getData: fetchBusiness, updateData: updateBusiness} = useCallDataApi('business')
    const {getData: fetchSettings} = useCallDataApi('general')
    const {postData} = useCallDataApi('')
    const theme = useTheme()

    const personBillingRef = useRef(null)
    const companyBillingRef = useRef(null)

    useEffect(() => {
        getBusiness()
        getSettings()
    }, [])

    const handleChange = (event) => {
        setAszfAccepted(event.target.checked);
    };

    const getBusiness = () => {
        fetchBusiness('get_business').then(b => {
            setBusiness(b)
            setEditedBusiness(b)
        })
    }

    const getSettings = () => fetchSettings('get_price').then(p => setPrice(p))

    const saveInvoiceData = () => {
        let o = {
            name: editedBusiness.name,
            vat_number: editedBusiness.vat_number ? editedBusiness.vat_number : '',
            country: editedBusiness.country,
            state: editedBusiness.state,
            zip: editedBusiness.zip,
            city: editedBusiness.city,
            address: editedBusiness.address,
            incorporation_type: editedBusiness.incorporation_type,
        }

        const if_company_vat = o.incorporation_type === 'company' ? o.vat_number : true;

        if (o.name &&
            if_company_vat &&
            o.country &&
            o.state &&
            o.zip &&
            o.city &&
            o.address &&
            o.incorporation_type) {
            updateBusiness(`${business?.id}/`, o)
                .then(b => {
                    setBusiness(b)
                    setEditedBusiness(b)
                    setInvoiceDataProvided(true)
                })


        } else {
            alert('Minden adatot meg kell adni!')
        }
    }

    const subscribe = (type) => {
        postData('register_card/', {subscription: type})
            .then(r => {
                window.location.href = r?.paymentLink
            })
            .catch(() => alert('Valami nem sikerült!'))
    }

    return <WizardBase label="Első lépések">
        <div className="main" style={{background: '#F3F5F7'}}>
            <Stack spacing={1} marginBottom={2}>
                <Typography variant='headline2'>Dinobooking varázsló</Typography>
                <Typography variant='body2' color='#536B74'>
                    Ahhoz hogy el tudd kezdeni használni a Dinobookingot,
                    szükségünk van a számlázási, illetve kártya adataidra.
                </Typography>
                <span>
                    <Typography variant='body2' color='#ff0000'>
                        Kérjük, hogy az adatokat pontosan add meg, hiszen ezekkel fogjuk kiállítani a számlát neked, illetve ezeket az adatokat jelenítjük meg
                    az ügyfeleid részére a SimplePay adattovábbítási nyilatkozatodban, ha beállítod az online fizetés
                    elfogadását.
                    </Typography>
                </span>
                <Typography variant='body2' color='#536B74'>
                    A kártyádat nem fogjuk megterhelni, csak az
                    ingyenes próbaidőszak lejárta után. Erről 3 nappal előtte értesítünk és az előfizetést bármikor le
                    tudod mondani.
                </Typography>
            </Stack>
            {!invoiceDataProvided ?
                <div className="white-card">
                    <FormControl>
                        <RadioGroup
                            name="controlled-radio-buttons-group"
                            value={editedBusiness?.incorporation_type}
                            onChange={(event => {
                                setEditedBusiness({...editedBusiness, incorporation_type: event.target.value})
                            })}
                            sx={{my: 1}}
                        >
                            <FormControlLabel
                                value="company"
                                control={<Radio/>}
                                label={<Typography
                                    color='#6F8E9A'
                                    variant='caption'>
                                    Vállalkozás
                                </Typography>}/>

                            <FormControlLabel
                                value="taxable_person"
                                control={<Radio/>}
                                label={<Typography
                                    color='#6F8E9A'
                                    variant='caption'>
                                    Magánszemély
                                </Typography>}/>
                        </RadioGroup>
                    </FormControl>
                    {editedBusiness?.incorporation_type === 'company' ?
                        <CompanyBilling
                            data={editedBusiness}
                            setData={setEditedBusiness}
                            reference={companyBillingRef}
                        />
                        :
                        <PersonBilling
                            data={editedBusiness}
                            setData={setEditedBusiness}
                            reference={personBillingRef}
                        />
                    }
                    <Stack direction='row' marginTop={2} justifyContent='end'>
                        <Button
                            variant='contained'
                            size='large'
                            endIcon={<NavigateNextRoundedIcon/>}
                            onClick={saveInvoiceData}>
                            Tovább a kártyaadatokhoz
                        </Button>
                    </Stack>
                </div> :
                <div className='white-card'>
                    <FormControlLabel control={<Checkbox
                        checked={aszfAccepted}
                        onChange={handleChange}
                        inputProps={{'aria-label': 'controlled'}}
                    />} label={<Typography variant='body2' color='#536B74'>
                        Elfogadom az <a rel="noreferrer" style={{color: theme.palette.primary[700]}} target='_blank'
                                        href="https://dinobooking.hu/aszf">Általános szerződési feltételek</a>et,
                        illetve elolvastam és megértettem az <a rel="noreferrer"
                                                                style={{color: theme.palette.primary[700]}}
                                                                target='_blank'
                                                                href="https://dinobooking.hu/adatvedelmi-tajekoztato">Adatvédelmi
                        tájékoztató</a>t.
                    </Typography>}/>


                    <Grid container spacing={1} marginBottom={2} marginTop={2}>
                        <Grid item xs={12} md={6}>
                            <Stack direction='row' spacing={2} sx={{
                                border: '1px solid',
                                borderColor: theme.palette.primary[50],
                                borderRadius: '16px',
                                padding: '16px 24px'
                            }}>
                                <AccountCircleRoundedIcon sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                <Stack spacing={2}>
                                    <Typography variant='subtitle1' color={theme.palette.primary[600]}>Egyszemélyes
                                        vállalkozói csomag</Typography>
                                    <Typography variant='body2' color='#38474D'>Ideális egy személyes vállalkozásoknak
                                        ahol
                                        egy szolgáltató végzi a munkát.</Typography>
                                    <Stack spacing={1} justifyContent='center'>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>akármikor
                                            lemondható</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>foglalások
                                            kezelése</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>saját weboldal</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>értesítések
                                            küldése</Typography>
                                    </Stack>
                                    <Typography variant='subtitle2'
                                                color={theme.palette.primary[700]}>{thousandSeparator(price.one_price)} Ft/hó</Typography>
                                    <Button onClick={() => subscribe('one')}
                                            disabled={editedBusiness?.subscription === 'one' || !aszfAccepted}
                                            variant='contained' sx={{
                                        padding: '15px 12px 15px 16px',
                                        width: 'fit-content'
                                    }}>{editedBusiness?.subscription === 'one' ? 'Előfizetési csomag aktív' : 'Előfizetek kártyaregisztrációval'}</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack direction='row' spacing={2} sx={{
                                border: '1px solid',
                                borderColor: theme.palette.primary[50],
                                borderRadius: '16px',
                                padding: '16px 24px'
                            }}>
                                <PeopleRoundedIcon sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                <Stack spacing={2}>
                                    <Typography variant='subtitle1' color={theme.palette.primary[600]}>Többszemélyes
                                        vállalkozói csomag</Typography>
                                    <Typography variant='body2' color='#38474D'>Ideális több fős vállalkozásoknak ahol
                                        eltérő személyekhez lehet időpontot foglalni.</Typography>
                                    <Stack spacing={1} justifyContent='center'>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>akármikor
                                            lemondható</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>kollégák
                                            feltöltése</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>foglalások
                                            kezelése</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>saját weboldal</Typography>
                                        <Typography variant='caption'><CheckIcon
                                            style={{color: theme.palette.primary[500]}}/>értesítések
                                            küldése</Typography>
                                    </Stack>
                                    <Typography variant='subtitle2'
                                                color={theme.palette.primary[700]}>{thousandSeparator(price.multiple_price)} Ft/hó</Typography>
                                    <Button onClick={() => subscribe('multiple')}
                                            disabled={editedBusiness?.subscription === 'multiple' || !aszfAccepted}
                                            variant='contained'
                                            sx={{
                                                padding: '15px 12px 15px 16px',
                                                width: 'fit-content'
                                            }}>{editedBusiness?.subscription === 'multiple' ? 'Előfizetési csomag aktív' : 'Előfizetek kártyaregisztrációval'}</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Button size='large' startIcon={<ArrowBackIosRoundedIcon/>}
                            onClick={() => setInvoiceDataProvided(false)}>Vissza a számlázási adatokhoz</Button>
                </div>}
        </div>
    </WizardBase>
}

export default CardRegistration