import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {validateRequired} from "../../../components/formValidation/validators";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {Stack, Typography} from "@mui/material";
import CustomDatePicker from "../../../components/formValidation/CustomDatePicker";
import {formatHuman} from "../../../utils/dateUtils";

const LeaveData = ({reference, obj}) => {
    const today = new Date()

    const [data, setData] = useState({
        name: obj?.name || '',
        from_date: obj?.from_date || formatHuman(today),
        end_date: obj?.end_date || formatHuman(today),
    })

    const [errors, setErrors] = useState({
        name: false,
        from_date: false,
        end_date: false,
    })

    const validators = {
        name: [validateRequired],
        from_date: [validateRequired],
        end_date: [validateRequired]
    }

    const validateNotEndsBeforeStarts = obj => {
        const start = new Date(obj?.from_date)
        const end = new Date(obj?.end_date)
        const validDates = start <= end;
        return {
            valid: validDates,
            error: validDates ? false : 'Nem kezdődhet hamarabb mint ahogy véget ér!'
        }
    }

    return <ErrorHandlingForm
        reference={reference}
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        customValidators={[validateNotEndsBeforeStarts]}
    >
        <Stack spacing={1}>
            <Typography variant='caption' aria-labelledby='name' color='primary.main'>
                Szabadság neve*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='name'
                id='name'
                variant='standard'
            />
            <Typography variant='caption' aria-labelledby='from_date' color='primary.main'>
                Szabadság kezdete*
            </Typography>
            <CustomDatePicker
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='from_date'
                id='from_date'
                disablePast
            />
            <Typography variant='caption' aria-labelledby='end_date' color='primary.main'>
                Szabadság vége*
            </Typography>
            <CustomDatePicker
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='end_date'
                id='end_date'
                disablePast
            />
        </Stack>
    </ErrorHandlingForm>
}

export default LeaveData