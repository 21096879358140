import {MenuItem, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {validateNonNegativeNumber, validateNumber, validateRequired} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomSelect from "../../../components/formValidation/CustomSelect";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";


const PriceData = ({reference, obj, prices}) => {
    const {getData: fetchService} = useCallDataApi('service')
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([])
    useEffect(() => {
        setLoading(true)
        fetchService('get_for_business').then(c => {
            setServices(c)
        }).finally(() => setLoading(false))
    }, []);

    const usedServices = prices.map(p => p.service.id)
    const usableColleagues = services.filter(coll => !usedServices.includes(coll.id) || coll.id === obj?.service.id)

    const [data, setData] = useState({
        service: obj?.service || '',
        price: obj?.price || ''
    });

    const [errors, setErrors] = useState({
        service: false,
        price: false,
    });

    const validators = {
        service: [validateRequired],
        price: [validateRequired, validateNumber, validateNonNegativeNumber],
    }


    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Szolgáltatás*
            </Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='service'
                variant='standard'
            >
                {usableColleagues?.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
            </CustomSelect>

            <Typography variant='caption' color='primary.main'>
                Ár*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='price'
                variant='standard'
            />
        </Stack>

        <Loading isLoading={loading}/>
    </ErrorHandlingForm>
}

export default PriceData