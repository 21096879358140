import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateRequired, validateUrl} from "../../../components/formValidation/validators";


const Redirect = ({reference, data, setData, required}) => {
    const [errors, setErrors] = useState({
        redirect_url: false,
    });

    const validators = {
        redirect_url: required ? [validateRequired, validateUrl, ] : [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '100%' // Half width in row direction
                },
            },
        }}>
            <Stack spacing={1}>
                <Typography variant='caption' color='primary.main'>Címsor</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='redirect_url'
                    variant='standard'
                />
            </Stack>
        </Stack>
    </ErrorHandlingForm>
}

export default Redirect;