import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {
    validateOneOf,
    validateRequired
} from "../../../components/formValidation/validators";
import {Box, FormControlLabel, Grid, Radio, Typography} from "@mui/material";
import CustomRadioGroup from "../../../components/formValidation/CustomRadioGroup";

const WorkHourSelector = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        mode: false,
    });

    const validators = {
        mode: [validateRequired, value => validateOneOf(value, ['open_in_worktime', 'open_only_at'])],
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
    >
        <Box className="radio-selector" marginTop={2} marginBottom={2}>
            <CustomRadioGroup
                aria-labelledby="radio-buttons-group-label"
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='mode'
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={(data?.mode === 'open_in_worktime') && 'active-radio'}>
                            <FormControlLabel
                                value='open_in_worktime'
                                control={<Radio/>}
                                label="Munkaidő"
                            />
                            <Typography color='#536B74' variant='body2'>
                                Abban az esetben válaszd ezt az opciót,
                                ha munkaidőd legtöbbször ugyan arra az időre esik. Pl.: hétfőtől
                                péntekig 9-töl 5-ig
                                {JSON.stringify(data?.mode === 'open_in_worktime')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={(data?.mode === 'open_only_at') && 'active-radio'}>
                            <FormControlLabel
                                value='open_only_at'
                                control={<Radio/>}
                                label="Csak bizonyos napokon"
                            />
                            <Typography color='#536B74' variant='body2'>
                                Abban az esetben válaszd ezt az opciót,
                                ha csak bizonyos napokon dolgozol. Pl.: Csak 2025-02-12 9-től 2-ig és
                                2025-02-15 10-től 12-ig
                                {JSON.stringify(data?.mode === 'open_only_at')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CustomRadioGroup>
        </Box>
    </ErrorHandlingForm>
}

export default WorkHourSelector