import {MenuItem, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import CustomSelect from "../../../../components/formValidation/CustomSelect";
import Typography from "@mui/material/Typography";
import {
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../../../components/formValidation/validators";
import useCallDataApi from "../../../../hooks/data";
import CustomTextField from "../../../../components/formValidation/CustomTextField";

const PriceData = ({reference, obj, prices}) => {
    const {getData: fetchColleague} = useCallDataApi('colleague')
    const [colleagues, setColleagues] = useState([])
    useEffect(() => {
        fetchColleague('get_for_business').then(c => {
            setColleagues(c)
        })
    }, []);

    const usedColleagues = prices.map(p => p.colleague.id)
    const usableColleagues = colleagues.filter(coll => !usedColleagues.includes(coll.id) || coll.id === obj?.colleague.id)

    const [data, setData] = useState({
        colleague: obj?.colleague?.id || '',
        price: obj?.price || ''
    });

    const [errors, setErrors] = useState({
        colleague: false,
        price: false,
    });

    const validators = {
        colleague: [validateRequired],
        price: [validateRequired, validateNumber, validateNonNegativeNumber],
    }
    

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Kolléga*
            </Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='colleague'
                variant='standard'
            >
                {usableColleagues?.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
            </CustomSelect>

            <Typography variant='caption' color='primary.main'>
                Ár*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='price'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default PriceData