import Typography from "@mui/material/Typography";
import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import {
    validateNonNegativeNumber,
    validateNumber, validateOneOf,
    validateRequired
} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomSelect from "../../../components/formValidation/CustomSelect";


const Invoice = ({reference, data, setData}) => {

    const [errors, setErrors] = useState({
        vat_percent: false,
    });

    const validators = {
        vat_percent: [validateRequired, validateNumber, validateNonNegativeNumber, value => validateOneOf(value, [27, 18, 5, 0])],
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
    >
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography id="vat-percent-label" variant="caption" color="primary.main">
                Áfa tartalom*
            </Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="vat_percent"
                variant="standard"
                inputProps={{
                    "aria-labelledby": "vat-percent-label",
                    "aria-required": "true",
                }}
            >
                <MenuItem value={27}>27%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={0}>0%</MenuItem>
            </CustomSelect>
        </Stack>
    </ErrorHandlingForm>
}

export default Invoice