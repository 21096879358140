import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import DinoDialog from "../../../components/Dialog";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useCallDataApi from "../../../hooks/data";
import {useEffect, useRef, useState} from "react";
import Loading from "../../../components/Loading";
import LeaveData from "../../colleague/components/LeaveData";


const Leaves = ({businessId}) => {
    const {
        getData: fetchLeave,
        deleteData: deleteLeave,
        createData: createLeave,
        updateData: updateLeave
    } = useCallDataApi('leave')
    const [newLeaveOpen, setNewLeaveOpen] = useState(false)
    const [editLeaveOpen, setEditLeaveOpen] = useState(false)
    const [deleteLeaveOpen, setDeleteLeaveOpen] = useState(false)
    const [current, setCurrent] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [leaves, setLeaves] = useState([])
    const newLeaveForm = useRef(null)
    const editLeaveForm = useRef(null)

    useEffect(() => {
        setLoading(true)
        fetchLeave('get_for_business')
            .then(l => setLeaves(l))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addLeave = () => {
        const data = newLeaveForm.current.getData()
        if (data) {
            setNewLeaveOpen(false)
            setLoading(true)
            createLeave({...data, business: businessId})
                .then(l => setLeaves([...leaves, l]))
                .finally(() => setLoading(false))
        }
    }

    const editLeave = () => {
        const data = editLeaveForm.current.getData()
        if (data) {
            setEditLeaveOpen(false)
            setLoading(true)
            updateLeave(current, {
                ...data,
                business: businessId,
            })
                .then(l => setLeaves(
                    leaves.map((leave) =>
                        leave.id === l.id ? l : leave
                    )
                ))
                .finally(() => setLoading(false))
        }
    }

    const removeLeave = () => {
        setDeleteLeaveOpen(false)
        setLoading(true)
        deleteLeave(current).then(() => setLeaves(leaves.filter(l => l?.id !== current))).finally(() => setLoading(false))
    }

    const findLeave = id => leaves.find(l => l?.id === id)

    return <div className="white-card">
        <Typography variant='subtitle1'>Céges szabadságok beállítása</Typography>
        <Typography variant='body2' color='#536B74'>
            Adj hozzá szabadságokat! A kollégák egyedi
            szabadságát a kollégák menüpont alatt tudod beállítani!
        </Typography>
        {leaves.length === 0 &&
            <Box
                sx={{border: '1px dashed', borderColor: '#F1F4F5', borderRadius: '16px', marginTop: 2, marginBottom: 2}}>
                <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                    <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek szabadságok</Typography>
                    <Typography variant='body2' color='#6F8E9A'>
                        Adj hozzá új szabadságot az alábbi gombra kattintva! 👇
                    </Typography>
                </Stack>
            </Box>}
        {leaves.map((l, i) => <Grid container spacing={2} key={`leave_${i}`} sx={{marginTop: 2, marginBottom: 2, display: 'flex', alignItems: 'center'}}>
            <Grid item xs={12} md={3}>
                <Typography variant='subtitle2'>{l?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography
                    variant='button'
                    sx={{color: '#536B74'}}>
                    {l?.from_date} - {l?.end_date}
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography
                    variant='caption'
                    sx={{color: '#38474D'}}>
                    {l?.business ? 'Céges szabadság' : 'Saját szabadság'}
                </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{display: 'flex', gap: 1}}>
                <IconButton sx={{
                    alignSelf: 'center',
                    color: 'primary.main',
                    border: '2px solid',
                    borderColor: 'primary.main'
                }} onClick={() => {
                    setCurrent(l.id)
                    setEditLeaveOpen(true)
                }}>
                    <EditRoundedIcon/>
                </IconButton>
                <IconButton sx={{
                    alignSelf: 'center',
                    color: 'primary.main',
                    border: '2px solid',
                    borderColor: 'primary.main'
                }} onClick={() => {
                    setCurrent(l.id)
                    setDeleteLeaveOpen(true)
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Grid>
        </Grid>)}
        <Button
            variant='contained'
            onClick={() => setNewLeaveOpen(true)}
            endIcon={<AddCircleRoundedIcon/>}>
            Új szabadság hozzáadása
        </Button>


        <DinoDialog
            open={deleteLeaveOpen}
            title='Szabadság törlése'
            subtitle='Biztos vagy benne hogy szeretnéd törölni a szabadságot?'
            handleClose={() => setDeleteLeaveOpen(false)}
            actions={<Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setDeleteLeaveOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={removeLeave} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>}
        />


        <DinoDialog
            open={newLeaveOpen}
            title='Új szabadság hozzáadása'
            subtitle='Írd be mikor szeretnél szabdságot beállítani, és nevezd el!'
            handleClose={() => setNewLeaveOpen(false)}
            actions={<Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setNewLeaveOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={addLeave} endIcon={<SaveRoundedIcon/>}>Hozzáadás</Button>
            </Stack>}
        >
            <LeaveData reference={newLeaveForm}/>
        </DinoDialog>

        <DinoDialog
            open={editLeaveOpen}
            title='Szabadság szerkesztése'
            subtitle='Írd át a szabadság nevét és dátumait'
            handleClose={() => setEditLeaveOpen(false)}
            actions={<Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setEditLeaveOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={editLeave} endIcon={<EditRoundedIcon/>}>Szerkesztés</Button>
            </Stack>}
        >
            <LeaveData reference={editLeaveForm} obj={findLeave(current)}/>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Leaves