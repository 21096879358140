import {Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateMaxLength, validateRequired} from "../../../components/formValidation/validators";
import hero from "../../../assets/Hero section.png";
import useCallDataApi from "../../../hooks/data";
import Button from "@mui/material/Button";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {isUnderMaxFileSize} from "../../../utils/ValidationUtils";
import ConfigContext from "../../../context/ConfigContext";

const Home = ({reference, disabled, data, setData}) => {
    const [images, setImages] = useState([]);
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)

    useEffect(() => {
        fetchHomeScreenImages('get_for_business').then(i => setImages(i));
    }, []);

    const {
        getData: fetchHomeScreenImages,
        createDataFormData: createHomeScreenImages,
        deleteData: removeImage
    } = useCallDataApi('home-screen-image')

    const [errors, setErrors] = useState({
        hero_heading: false,
        hero_text: false,
    });

    const validators = {
        hero_heading: [validateRequired, value => validateMaxLength(255, value)],
        hero_text: [validateRequired, ],
    }

    const handleImageDelete = (id) => {
        removeImage(id).then(s => setImages([...images.filter(i => i.id !== id)]))
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '50%' // Half width in row direction
                },
            },
        }}>
            <Stack spacing={1}>
                <Typography variant='caption' color='primary.main'>Címsor</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='hero_heading'
                    variant='standard'
                    disabled={disabled}
                />
                <Typography variant='caption' color='primary.main'>Alcím</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='hero_text'
                    variant='standard'
                    disabled={disabled}
                    multiline
                    rows={4}
                />
                {!disabled && <Grid spacing={1} container>
                    {images.map((p, i) => <Grid item xs={6} key={`home_screen_image_${i}`}>
                        <Button onClick={() => handleImageDelete(p.id)} sx={{
                            width: '96px',
                            height: '96px',
                            background: `url(${baseUrl + p.image})`,
                            backgroundSize: 'cover',
                            '&:hover': {background: `url(${baseUrl + p.image})`, backgroundSize: 'cover'}
                        }}><DeleteForeverRoundedIcon/></Button>
                    </Grid>)}
                    <Grid item xs={6}><Button variant='outlined' component='label' sx={{
                        width: '96px',
                        height: '96px'
                    }}><AddCircleOutlinedIcon/><input onChange={event => {
                        const file = event.target.files[0];
                        if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                            createHomeScreenImages({image: file})
                                .then(i => setImages([...images, i]))
                        } else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)

                    }} type="file" hidden accept='image/*'/></Button></Grid>
                </Grid>}
            </Stack>
            <img src={hero} alt="Hero" style={{ width: '100%' }} />
        </Stack>
    </ErrorHandlingForm>
}

export default

Home;