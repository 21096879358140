import {Stack} from "@mui/material";
import {useContext, useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import {validateMaxFileSize} from "../../../../components/formValidation/validators";
import ConfigContext from "../../../../context/ConfigContext";
import CustomImageField from "../../../../components/formValidation/CustomImageField";


const Image = ({reference, data, setData}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const [errors, setErrors] = useState({
        image: false,
    });

    const validators = {
        image: [file => validateMaxFileSize(file, MAX_IMG_SIZE)],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomImageField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='image'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default Image