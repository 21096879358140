import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {useState} from "react";
import {validateRequired} from "../../../components/formValidation/validators";

const DeleteColleagueData = ({reference}) => {
    const validateDelete = (value) => {
        return {
            valid: value === 'TÖRLÉS',
            error: 'Írd be hogy "TÖRLÉS", ha törölni szeretnéd a kollégát.'
        }
    }

    const [data, setData] = useState({
        delete: ''
    })

    const [errors, setErrors] = useState({
        delete: false
    })

    const validators = {
        delete: [validateRequired, validateDelete]
    }


    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
    >
        <Stack spacing={1}>
            <Typography variant='caption'>
                Ha biztosan törölni szeretné a kollégát, gépelje be a "törlés" szót
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='delete'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default DeleteColleagueData