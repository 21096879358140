import { TextField } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import locale from "date-fns/locale/hu";
import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CustomTimePicker = ({ errors, setErrors, data, setData, name, validators, ...rest }) => {

    const handleChange = (newValue) => {
        setData({ ...data, [name]: newValue });
    };

    const handleBlur = (newValue) => {
        for (let validator of validators[name]) {
            const { valid, error } = validator(newValue);
            if (!valid) {
                setErrors({ ...errors, [name]: error });
                break;
            } else {
                setErrors({ ...errors, [name]: false});
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <TimePicker
                value={data[name]}
                onChange={(e) => {
                    handleBlur(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                {...rest}
                InputAdornmentProps={{ position: 'start' }}
                renderInput={(params) => (
                    <TextField
                        error={Boolean(errors[name])}
                        helperText={errors[name] && errors[name]}
                        id={name}
                        InputAdornmentProps={{ position: 'start' }}
                        variant="standard"
                        name={name}
                        {...params}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomTimePicker;