import {FormControl, FormControlLabel, Checkbox, Typography} from "@mui/material";

const CustomOnlyCheckbox = (
    {
        // errors,
        // setErrors,
        data,
        setData,
        name,
        // validators,
        // label,
        ...rest
    }) => {

    const handleChange = (event) => {
        const {name, checked} = event.target;
        setData({...data, [name]: checked});
    };

    // const handleBlur = (event) => {
    //     const {name, checked} = event.target;
    //     for (let validator of validators[name]) {
    //         const {valid, error} = validator(checked);
    //         if (!valid) {
    //             setErrors({...errors, [name]: error});
    //             break;
    //         } else {
    //             setErrors({...errors, [name]: false});
    //         }
    //     }
    // };

    return <Checkbox
        checked={data[name] || false}
        onChange={(e) => {
            handleChange(e);
            // handleBlur(e);
        }}
        name={name}
        {...rest}
    />
};

export default CustomOnlyCheckbox;
