import {useState} from "react";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {validateEmail, validatePhoneNumber, validateRequired} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomPhoneField from "../../../components/formValidation/CustomPhoneField";


const GeneralData = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        last_name: false,
        first_name: false,
        email: false,
        phone: false,
        comment: false,
    });

    const validators = {
        last_name: [validateRequired],
        first_name: [validateRequired],
        email: [validateRequired, validateEmail],
        phone: [validateRequired, validatePhoneNumber],
        comment: [],
    };

    return (
        <ErrorHandlingForm
            reference={reference}
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
            validators={validators}
        >
            <Stack spacing={2}>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} width='100%'>
                    <Stack spacing={1} width='100%'>
                        <Typography id="last-name-label" variant="caption" color='primary.main'>
                            Vezetéknév*
                        </Typography>
                        <CustomTextField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="last_name"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "last-name-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack>
                    <Stack spacing={1} width='100%'>
                        <Typography id="first-name-label" variant="caption" color='primary.main'>
                            Keresztnév*
                        </Typography>
                        <CustomTextField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="first_name"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "first-name-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} width='100%'>
                    <Stack spacing={1} width='100%'>
                        <Typography id="email-label" variant="caption" color='primary.main'>
                            Email*
                        </Typography>
                        <CustomTextField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="email"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "email-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack>
                    <Stack spacing={1} width='100%'>
                        <Typography id="phone-label" variant="caption" color='primary.main'>
                            Telefon*
                        </Typography>
                        <CustomPhoneField
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            name="phone"
                            variant="standard"
                            inputProps={{
                                "aria-labelledby": "phone-label",
                                "aria-required": "true",
                            }}
                        />
                    </Stack></Stack>
                <Typography id="comment-label" variant="caption" color='primary.main'>
                    Megjegyzés
                </Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="comment"
                    variant="standard"
                    multiline
                    rows={5}
                    inputProps={{
                        "aria-labelledby": "comment-label",
                    }}
                />
            </Stack>
        </ErrorHandlingForm>
    );
};

export default GeneralData;
