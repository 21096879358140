import AuthBase from "../AuthBase";
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState} from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MessageTemplateTemplate from "./MessageTemplateTemplate";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import Loading from "../../components/Loading";
import {HashLink} from "react-router-hash-link";
import {useSnackbar} from "notistack";
import NewEmailCustomer from "./components/NewEmailCustomer";
import ConfirmationEmailCustomer from "./components/ConfirmationEmailCustomer";
import ResignationEmailCustomer from "./components/ResignationEmailCustomer";
import AmendmentEmailCustomer from "./components/AmendmentEmailCustomer";
import ReminderEmailCustomer from "./components/ReminderEmailCustomer";
import NewEmailColleague from "./components/NewEmailColleague";
import ConfirmationEmailColleague from "./components/ConfirmationEmailColleague";
import ResignationEmailColleague from "./components/ResignationEmailColleague";
import AmendmentEmailColleague from "./components/AmendmentEmailColleague";
import ReminderEmailColleague from "./components/ReminderEmailColleague";
import {Prompt} from "react-router-dom";
import CustomOnlyCheckbox from "../../components/formValidation/CustomOnlyCheckbox";
import NewOrderColleague from "./components/NewOrderColleague";
import NewOrderCustomer from "./components/NewOrderCustomer";
import ResignationOrderCustomer from "./components/ResignationOrderCustomer";
import ResignationOrder from "./components/ResignationOrderColleague";

const Notifications = () => {
    const [messageVariables, setMessageVariables] = useState([])
    const [messageTemplate, setMessageTemplate] = useState(MessageTemplateTemplate)
    const [editedMessageTemplate, setEditedMessageTemplate] = useState(MessageTemplateTemplate)
    const {getData: fetchMessageVariables} = useCallDataApi('message-variable')
    const {getData: fetchMessageTemplate, updateData: updateMessageTemplate} = useCallDataApi('message-template')
    const theme = useTheme()
    const {enqueueSnackbar} = useSnackbar()
    const [loading, setLoading] = useState(false)
    const newEmailCustomerRef = useRef(null)
    const newEmailColleagueRef = useRef(null)
    const confirmationEmailCustomerRef = useRef(null)
    const confirmationEmailColleagueRef = useRef(null)
    const resignationEmailCustomerRef = useRef(null)
    const resignationOrderCustomerRef = useRef(null)
    const resignationEmailColleagueRef = useRef(null)
    const resignationOrderColleagueRef = useRef(null)
    const amendmentEmailCustomerRef = useRef(null)
    const amendmentEmailColleagueRef = useRef(null)
    const reminderEmailCustomerRef = useRef(null)
    const reminderEmailColleagueRef = useRef(null)
    const newOrderColleagueRef = useRef(null)
    const newOrderCustomerRef = useRef(null)

    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchMessageVariables(),
            fetchMessageTemplate('get_for_business')
        ]).then(([mv, mt]) => {
            setMessageTemplate(mt)
            setEditedMessageTemplate(mt)
            setMessageVariables(mv)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const editMessageTemplate = () => {
        setLoading(true)
        updateMessageTemplate(messageTemplate?.id, editedMessageTemplate)
            .then(m => {
                setMessageTemplate(m)
                setEditedMessageTemplate(m)
                enqueueSnackbar('Mentve', {variant: 'success'})
            })
            .finally(() => setLoading(false))
    }

    const hasUnsavedChanges = JSON.stringify(editedMessageTemplate) !== JSON.stringify(messageTemplate)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = ""; // Required for modern browsers
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            variant: 'contained',
            disabled: !hasUnsavedChanges,
            onClick: editMessageTemplate
        }
    }]

    const checkBoxStyle = {display: 'flex', justifyContent: 'center', alignItems: 'center'}

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    const reminderOptions = [
        {value: 1, label: '1 órával előtte'},
        {value: 2, label: '2 órával előtte'},
        {value: 3, label: '3 órával előtte'},
        {value: 4, label: '4 órával előtte'},
        {value: 24, label: '24 órával előtte'},
        {value: 48, label: '48 órával előtte'},
        {value: 72, label: '72 órával előtte'}
    ]

    return <AuthBase label='Értesítések' buttons={buttons} filterButtonsMobile={buttons}>
        <div className="main">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="white-card-sp">
                        <Grid container sx={{background: '#F3F5F7', padding: 3, borderRadius: '4px'}}>
                            <Grid item xs={2}/>
                            <Grid item xs={5}>
                                <Stack>
                                    <Typography align='center' variant='subtitle2'>Ügyfelek értesítése</Typography>
                                    <HashLink to='/notifications#client-notification'
                                              scroll={el => scrollWithOffset(el)}
                                              style={{textDecoration: 'none', textAlign: 'center'}}>
                                        <Button>Sablonok szerkesztése</Button>
                                    </HashLink>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>e-mail</Typography></Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>sms</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack>
                                    <Typography align='center' variant='subtitle2'>Kollégák értesítése</Typography>
                                    <HashLink to='/notifications#colleague-notification'
                                              scroll={el => scrollWithOffset(el)}
                                              style={{textDecoration: 'none', textAlign: 'center'}}>
                                        <Button>Sablonok szerkesztése</Button>
                                    </HashLink>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>e-mail</Typography></Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>sms</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Új Foglalás
                                </Typography>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_new_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_new_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_new_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_new_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Foglalás megerősítése
                                </Typography>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_confirmation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_confirmation_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_confirmation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_confirmation_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Lemondás
                                </Typography>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_resignation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_resignation_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_resignation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_resignation_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Foglalás módosítás
                                </Typography>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_amendment_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_amendment_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_amendment_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_amendment_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid
                                item
                                sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Emlékeztető
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        variant='standard'
                                        id="reminder-select"
                                        multiple
                                        value={editedMessageTemplate.reminder}
                                        onChange={(event) => setEditedMessageTemplate({
                                            ...editedMessageTemplate,
                                            reminder: event.target.value.map(value => parseInt(value, 10))
                                        })}
                                    >
                                        {reminderOptions?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_reminder_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_reminder_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_reminder_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_reminder_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Új megrendelés
                                </Typography>
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_new_order_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_new_order_sms'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_new_order_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_new_order_sms'
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}>
                                <Typography variant='button' className='table-heading' color='#000'>
                                    Megrendelés törlése
                                </Typography>
                            </Grid>

                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_order_resignation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_customer_order_resignation_sms'
                                />
                            </Grid>

                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_order_resignation_email'
                                />
                            </Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}>
                                <CustomOnlyCheckbox
                                    data={editedMessageTemplate}
                                    setData={setEditedMessageTemplate}
                                    name='notify_colleague_order_resignation_sms'
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Értesítésekhez használható változók</Typography>
                        <Grid container spacing={2} marginTop={2}>
                            {messageVariables.map((m, i) => <Grid key={`mv_${i}`} item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <Button variant='contained'
                                            sx={{
                                                color: theme.palette.primary[600],
                                                background: theme.palette.primary[100],
                                                padding: '8px 16px'
                                            }}
                                            endIcon={<ContentCopyIcon/>}
                                            onClick={() => {
                                                navigator.clipboard.writeText(m?.value).then(() => enqueueSnackbar('Másolva', {
                                                    variant: 'success',
                                                    action: (
                                                        <ContentCopyIcon fontSize="small"/>
                                                    )
                                                }))
                                            }}>
                                        {m?.name}
                                    </Button>
                                    <Typography variant='caption' color='#38474D'> - {m?.description}</Typography>
                                </Stack>
                            </Grid>)}
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography id='client-notification' variant='subtitle1'>
                        Ügyfelek értesítései
                    </Typography>
                    <Typography variant='body1' color='#536B74'>
                        Itt tudod szerkeszteni az ügyfeleknek kiküldött
                        értesítések szövegeit. A pontosabb értesítés érdekében használd a fennti változókat az
                        értesítések szövegében!
                    </Typography>

                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Új foglalás
                        </Typography>
                        <NewEmailCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={newEmailCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás megerősítése
                        </Typography>
                        <ConfirmationEmailCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={confirmationEmailCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás lemondása
                        </Typography>
                        <ResignationEmailCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={resignationEmailCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás módosítása
                        </Typography>
                        <AmendmentEmailCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={amendmentEmailCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalási emlékeztető
                        </Typography>
                        <ReminderEmailCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={reminderEmailCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Új megrendelés
                        </Typography>
                        <NewOrderCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={newOrderCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Új megrendelés
                        </Typography>
                        <ResignationOrderCustomer
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={resignationOrderCustomerRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography id='colleague-notification' variant='subtitle1'>
                        Kollégák értesítései
                    </Typography>
                    <Typography variant='body1' color='#536B74'>
                        Itt tudod szerkeszteni az kollégáknak kiküldött
                        értesítések szövegeit. A pontosabb értesítés érdekében használd a fennti változókat az
                        értesítések szövegében!
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Új foglalás
                        </Typography>
                        <NewEmailColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={newEmailColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás megerősítése
                        </Typography>
                        <ConfirmationEmailColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={confirmationEmailColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás lemondása
                        </Typography>
                        <ResignationEmailColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={resignationEmailColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalás módosítása
                        </Typography>
                        <AmendmentEmailColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={amendmentEmailColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Foglalási emlékeztető
                        </Typography>
                        <ReminderEmailColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={reminderEmailColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Új megrendelés
                        </Typography>
                        <NewOrderColleague
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={newOrderColleagueRef}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>
                            Megrendelés törlése
                        </Typography>
                        <ResignationOrder
                            data={editedMessageTemplate}
                            setData={setEditedMessageTemplate}
                            reference={resignationOrderColleagueRef}
                        />
                    </div>
                </Grid>

            </Grid>
        </div>

        <Loading isLoading={loading}/>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />
    </AuthBase>
}

export default Notifications