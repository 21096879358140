const MessageTemplateTemplate = {
    business: '',
    id: '',

    notify_colleague_new_order_sms: false,
    notify_colleague_new_order_email: false,
    notify_customer_new_order_sms: false,
    notify_customer_new_order_email: false,

    notify_colleague_order_resignation_sms: false,
    notify_colleague_order_resignation_email: false,
    notify_customer_order_resignation_sms: false,
    notify_customer_order_resignation_email: false,

    notify_colleague_new_sms: false,
    notify_colleague_confirmation_sms: false,
    notify_colleague_resignation_sms: false,
    notify_colleague_amendment_sms: false,
    notify_colleague_reminder_sms: false,

    notify_customer_new_sms: false,
    notify_customer_confirmation_sms: false,
    notify_customer_resignation_sms: false,
    notify_customer_amendment_sms: false,
    notify_customer_reminder_sms: false,

    notify_colleague_new_email: false,
    notify_colleague_confirmation_email: false,
    notify_colleague_resignation_email: false,
    notify_colleague_amendment_email: false,
    notify_colleague_reminder_email: false,

    notify_customer_new_email: false,
    notify_customer_confirmation_email: false,
    notify_customer_resignation_email: false,
    notify_customer_amendment_email: false,
    notify_customer_reminder_email: false,

    new_order_email_title: '',
    new_order_email: '',
    new_order_sms: '',
    reservation_new_email_title: '',
    reservation_new_email: '',
    reservation_confirmation_email_title: '',
    reservation_confirmation_email: '',
    reservation_new_sms: '',
    reservation_confirmation_sms: '',
    reservation_resignation_email_title: '',
    reservation_resignation_email: '',
    reservation_resignation_sms: '',
    reservation_amendment_email_title: '',
    reservation_amendment_email: '',
    reservation_amendment_sms: '',
    reservation_reminder_email_title: '',
    reservation_reminder_email: '',
    reservation_reminder_sms: '',
    colleague_reservation_new_email_title: '',
    colleague_reservation_new_email: '',
    colleague_reservation_new_sms: '',
    colleague_reservation_confirmation_email_title: '',
    colleague_reservation_confirmation_email: '',
    colleague_reservation_confirmation_sms: '',
    colleague_reservation_resignation_email_title: '',
    colleague_reservation_resignation_email: '',
    colleague_reservation_resignation_sms: '',
    colleague_reservation_amendment_email_title: '',
    colleague_reservation_amendment_email: '',
    colleague_reservation_amendment_sms: '',
    colleague_reservation_reminder_email_title: '',
    colleague_reservation_reminder_email: '',
    colleague_reservation_reminder_sms: '',
    reminder: [1]
}

export default MessageTemplateTemplate