import React, {useState} from "react";
import {
    TextField,
    Box,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Stack
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

const TimeRangePicker = ({start, end, onDelete, onChange}) => {
    const today = dayjs().format('YYYY-MM-DD'); // Get today's date in 'YYYY-MM-DD' format
    const [startTime, setStartTime] = useState(dayjs(`${today} ${start}`, 'HH:mm'));
    const [endTime, setEndTime] = useState(dayjs(`${today} ${end}`, 'HH:mm'));
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = () => {
        setIsEditing(false);
        onChange(startTime?.format("HH:mm"), endTime?.format("HH:mm"));
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: 1,
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: "16px",
                backgroundColor: "primary.50",
            }}
        >
            <Typography variant="caption" color='primary.700' fontSize='100%'>
                {startTime?.format("HH:mm")} - {endTime?.format("HH:mm")}
            </Typography>

            <IconButton onClick={() => setIsEditing(true)} color="primary">
                <EditIcon/>
            </IconButton>

            <IconButton onClick={onDelete} color="primary">
                <DeleteIcon/>
            </IconButton>

            {/* Dialog for editing */}
            <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography variant='caption' color='primary.main'
                                    aria-labelledby='startTime'>Kezdete*</Typography>
                        <TimePicker
                            id='startTime'
                            value={startTime}
                            onChange={(newValue) => setStartTime(dayjs(newValue, 'HH:mm'))}
                            renderInput={(params) => <TextField {...params} variant='standard'/>}
                        />
                        <Typography variant='caption' color='primary.main' aria-labelledby='endTime'>Vége*</Typography>
                        <TimePicker
                            id='endTime'
                            value={endTime}
                            onChange={(newValue) => setEndTime(dayjs(newValue, 'HH:mm'))}
                            renderInput={(params) => <TextField {...params} variant='standard'/>}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditing(false)} color="secondary">
                        Mégsem
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Mentés
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TimeRangePicker