import { FormControl, FormControlLabel, Switch, Typography } from "@mui/material";

const CustomSwitch = ({
                          errors,
                          setErrors,
                          data,
                          setData,
                          name,
                          validators,
                          labelOn,
                          labelOff,
                          ...rest
                      }) => {

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setData({ ...data, [name]: checked });
    };

    const handleBlur = (event) => {
        const { name, checked } = event.target;
        for (let validator of validators[name]) {
            const { valid, error } = validator(checked);
            if (!valid) {
                setErrors({ ...errors, [name]: error });
                break;
            } else {
                setErrors({ ...errors, [name]: false });
            }
        }
    };

    return (
        <FormControl error={Boolean(errors[name])}>
            <FormControlLabel
                control={
                    <Switch
                        checked={data[name] || false}
                        onChange={(e) => {
                            handleChange(e);
                            handleBlur(e);
                        }}
                        name={name}
                        {...rest}
                    />
                }
                label={
                    <Typography variant="caption" color={errors[name] ? "error" : "#1C2427"}>
                        {data[name] ? labelOn : labelOff}
                    </Typography>
                }
            />
            {errors[name] && <Typography variant="caption" color="error">{errors[name]}</Typography>}
        </FormControl>
    );
};

export default CustomSwitch;
