import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateMaxFileSize, validateMaxLength, validateRequired} from "../../../components/formValidation/validators";
import CustomImageField from "../../../components/formValidation/CustomImageField";
import ConfigContext from "../../../context/ConfigContext";


const Company = ({reference, data, setData}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext);


    const [errors, setErrors] = useState({
        public_name: false,
        public_phone: false,
        public_email: false,
        public_address: false,
        public_logo: false,
    });

    const validators = {
        public_name: [validateRequired, value => validateMaxLength(255, value)],
        public_phone: [value => validateMaxLength(255, value)],
        public_email: [value => validateMaxLength(255, value)],
        public_address: [value => validateMaxLength(511, value)],
        public_logo: [file => validateMaxFileSize(file, MAX_IMG_SIZE)],
    }
    
    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '50%' // Half width in row direction
                },
            },
        }}>
            <Stack spacing={1}>
                <Typography variant='caption' color='primary.main'>Vállalkozás neve</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='public_name'
                    variant='standard'
                />

                <Typography variant='caption' color='primary.main'>Vállalkozás publikus telefonszáma</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='public_phone'
                    variant='standard'
                />

                <Typography variant='caption' color='primary.main'>Vállalkozás publikus e-mail címe</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='public_email'
                    variant='standard'
                />

                <Typography variant='caption' color='primary.main'>Vállalkozás publikus címe</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='public_address'
                    variant='standard'
                />

                <Typography variant='caption' color='primary.main'>Vállalkozás logója</Typography>
                <CustomImageField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='public_logo'
                    variant='standard'
                />
            </Stack>
        </Stack>
    </ErrorHandlingForm>
}

export default Company;