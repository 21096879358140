
const plans = {
    MULTIPLE: 'multiple',
    ONE: 'one',
    NONE: 'none',
}

const hasPlan = (user, plan) => {
    return user?.subscription_plan === plan
}

export {plans, hasPlan}