import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {useState} from "react";
import {validateRequired} from "../../../components/formValidation/validators";

const OwnData = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        title: false,
    });

    const validators = {
        first_name: [validateRequired,],
        last_name: [validateRequired,],
        title: [validateRequired,],
    }
    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
    >
        <Stack spacing={1} marginTop={2} marginBottom={2}>

            <Typography variant='caption' color='primary.main'>
                Vezetéknév*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='last_name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Kersztnév*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='first_name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Titulus/Foglalkozás*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='title'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default OwnData;