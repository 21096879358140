import {Avatar, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {useContext} from "react";
import ConfigContext from "../../context/ConfigContext";


const CustomImageField = ({errors, setErrors, data, setData, name, validators, ...rest}) => {
    const {baseUrl} = useContext(ConfigContext)

    const handleChange = (event) => {
        const {name, files} = event.target
        setData({...data, [name]: files[0]})
    }

    const handleBlur = (event) => {
        const {name, files} = event.target;
        for (let validator of validators[name]) {
            const {valid, error} = validator(files[0])
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    }

    const getImage = () => {
        const pic = data[name]
        if (pic instanceof File) return URL.createObjectURL(pic)
        if (pic?.startsWith('http')) return pic
        return baseUrl + pic
    }

    return <Stack spacing={1}>
        <Stack direction='row' spacing={2}>
            <Avatar sx={{width: 60, height: 60}} src={getImage()} loading="true"/>
            <Button
                variant='outlined'
                component='label'
                endIcon={<FileUploadRoundedIcon/>}>
                Kép feltöltése <input
                type="file"
                name={name}
                accept='image/*'
                hidden
                onBlur={handleBlur}
                onChange={event => {
                    handleBlur(event)
                    handleChange(event)
                }}/>
            </Button>
        </Stack>
        {Boolean(errors[name]) && <Typography variant='body2' color='error'>{errors[name]}</Typography>}
    </Stack>
}

export default CustomImageField