import {Avatar, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import billingoLogo from "../../../assets/billingo_logo.png"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {useContext} from "react";
import DisabledWrapper from "../../../components/DisabledWrapper";
import AuthContext from "../../../context/AuthContext";
import {hasPlan, plans} from "../../../utils/subscriptionUtils";
import {hasPermission} from "../../../utils/permissionUtils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


const BillingoDisplay = ({credential, onMakeActive, onDeactivate, onEdit, onDelete}) => {
    const {name, active} = credential;
    const {getUser} = useContext(AuthContext)
    const user = getUser()

    const disabledCause = [{
        disabled: !hasPlan(user, plans.MULTIPLE),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
    }, {
        disabled: !hasPermission(user, 'billing', 'update'),
        message: 'Nincs jogosultságod ehhez a művelethez!'
    }]
    const disabledCauseDelete = [{
        disabled: !hasPlan(user, plans.MULTIPLE),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a használathoz!'
    }, {
        disabled: !hasPermission(user, 'payment', 'delete'),
        message: 'Nincs jogosultságod ehhez a művelethez!'
    }]
    return <Stack direction='row' justifyContent='space-between' sx={{width: '100%'}}>
        <Stack direction='row' spacing={2} alignItems='center'>
            <Avatar src={billingoLogo} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
            <div className="text-container">
                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                    <Typography align="left" variant="subtitle1">{name}</Typography>
                    {active && <Tooltip title="Jelenleg aktív"><div className='active-dot'/></Tooltip>}
                </Stack>
                <Typography align="left" variant="body2">Billingo</Typography>
            </div>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
            <DisabledWrapper disabledCause={disabledCause}>
                <IconButton variant='dino' onClick={() => onEdit(credential.id)} title="Szerkesztés">
                    <EditIcon/>
                </IconButton>
            </DisabledWrapper>
            <DisabledWrapper disabledCause={disabledCauseDelete}>
                <IconButton variant='dino' onClick={() => onDelete(credential.id)} title="Törlés">
                    <DeleteIcon/>
                </IconButton>
            </DisabledWrapper>


            <DisabledWrapper disabledCause={disabledCause}>
                {!active ?
                    <IconButton variant='dino' onClick={() => onMakeActive(credential.id)} title="Aktiválás">
                        <CheckCircleIcon/>
                    </IconButton>
                    :
                    <IconButton variant='dino' onClick={() => onDeactivate(credential.id)} title="Deaktiválás">
                        <CancelRoundedIcon/>
                    </IconButton>
                }
            </DisabledWrapper>

        </Stack>
    </Stack>
}

export default BillingoDisplay