import { Editor } from "@tinymce/tinymce-react";

const CustomTinyMCE = ({ errors, setErrors, data, setData, name, validators, ...rest }) => {
    const cleanContent = (content) => content.replace(/\n/g, ""); // Remove newlines

    const handleEditorChange = (content) => {
        const cleanedContent = cleanContent(content);
        setData({ ...data, [name]: cleanedContent });
    };

    const handleBlur = (content) => {
        const cleanedContent = cleanContent(content);
        for (let validator of validators[name]) {
            const { valid, error } = validator(cleanedContent);
            if (!valid) {
                setErrors({ ...errors, [name]: error });
                break;
            } else {
                setErrors({ ...errors, [name]: false });
            }
        }
    };

    return (
        <div>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                value={data[name]}
                init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    entity_encoding: "raw", // Disable HTML entity encoding
                    entities: "", // or 160,nbsp
                    ...rest.init, // Allow users to pass additional TinyMCE init options
                }}
                onEditorChange={(content) => {
                    handleEditorChange(content);
                    handleBlur(content);
                }}
                onBlur={(e) => handleBlur(e.target.getContent())}
                {...rest} // Pass any other props to the Editor
            />
            {errors[name] && <div style={{ color: "red", marginTop: "4px" }}>{errors[name]}</div>}
        </div>
    );
};

export default CustomTinyMCE;
