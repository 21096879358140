import Typography from "@mui/material/Typography";
import {Box, Button, Stack} from "@mui/material";
import AddCardRoundedIcon from "@mui/icons-material/AddCardRounded";
import {useEffect, useState, useContext} from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DinoDialog from "../../../components/Dialog";
import Loading from "../../../components/Loading";
import useCallDataApi from "../../../hooks/data";
import ConfigContext from "../../../context/ConfigContext";

const Cards = () => {
    const [loading, setLoading] = useState(false)
    const [deleteCardDialogOpen, setDeleteCardDialogOpen] = useState(false)
    const [current, setCurrent] = useState(1)
    const {postData: genericPost} = useCallDataApi('')
    const {getData: fetchCardInfo, postData: updateCardInfo} = useCallDataApi('card-info')
    const {ownUrl} = useContext(ConfigContext)
    const [cardInfos, setCardInfos] = useState([])

    useEffect(() => {
        setLoading(true)
        fetchCardInfo('get_for_business')
            .then(c => setCardInfos(c))
            .finally(() => setLoading(false))
    }, [])

    const changeActiveCard = (c) => {
        setLoading(true)
        updateCardInfo('change_active_card/', {card_id: c})
            .then(r => setCardInfos(r))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const deleteCard = (c) => {
        setLoading(true)
        updateCardInfo('delete_card/', {card_id: c})
            .then(r => setCardInfos(r))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const addCard = () => {
        setLoading(true)
        genericPost('register_card/', {redirect_url: `${ownUrl}/payment-over`})
            .then(r => window.location.href = r.paymentLink)
            .catch(() => {
                setLoading(false)
                alert('Sikertelen művelet!')
            })
    }

    const cardDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteCardDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteCardDialogOpen(false)
            deleteCard(current)
        }} endIcon={<DeleteForeverRoundedIcon/>}>Kártya törlése</Button>
    </>

    return <div className="white-card">
        <Typography variant='subtitle2'>Bankkártyák</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>
            Új kártya hozzáadása esetén időbe
            telhet míg az adatok ellenőrízve lesznek, addig az xxxx-xxxx-xxxx-xxxx számú kártyát mutatja
            a rendszer.
        </Typography>
        <Stack spacing={1} marginBottom={2}>
            {cardInfos?.map((c, i) => <Box key={`card_${i}`}>
                <Stack direction='row' spacing={2}>
                    <Stack spacing={1} className='bank-card'>
                        <Box
                            style={{
                                width: '30px',
                                height: '20px',
                                background: '#c79a00',
                                borderRadius: '5px',
                                border: '1px solid #866b00'
                            }}/>
                        <Typography variant='caption' color='#fff'>
                            {c.card_mask}
                        </Typography>
                        <Typography variant='caption' color='#fff'>
                            VALID: {c.expiry}
                        </Typography>
                    </Stack>
                    <Stack spacing={1}>
                        {c.active ?
                            <Button
                                disabled
                                variant='contained'>
                                Kiválasztva
                            </Button>
                            :
                            <Button
                                onClick={() => changeActiveCard(c.id)}
                                variant='contained'>
                                Kiválasztás
                            </Button>
                        }
                        <Button
                            onClick={() => {
                                setCurrent(c.id)
                                setDeleteCardDialogOpen(true)
                            }}>
                            Törlés
                        </Button>
                    </Stack>
                </Stack>
            </Box>)}
        </Stack>
        <Button
            variant='contained'
            endIcon={<AddCardRoundedIcon/>}
            onClick={addCard}>
            Kártya hozzáadása
        </Button>


        <DinoDialog
            open={deleteCardDialogOpen}
            handleClose={() => setDeleteCardDialogOpen(false)}
            title='Kártya törlése'
            subtitle='Biztosan törli ezt a kártyát?'
            actions={cardDeleteActions}>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Cards