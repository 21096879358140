import {MuiTelInput} from 'mui-tel-input'


const CustomPhoneField = ({errors, setErrors, data, setData, name, validators, ...rest}) => {
    const handleChange = (event) => {
        setData({...data, [name]: event});
    };

    const handleBlur = (event) => {
        for (let validator of validators[name]) {
            const {valid, error} = validator(event)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: false})
        }
    };

    return <MuiTelInput
        name={name}
        value={data[name]}
        onChange={e => {
            handleBlur(e)
            handleChange(e)
        }}
        onBlur={handleBlur}
        error={Boolean(errors[name])}
        helperText={errors[name] && errors[name]}
        {...rest}
    />
}

export default CustomPhoneField
