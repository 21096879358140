import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {validateEmail, validateRequired} from "../../../components/formValidation/validators";
import {Stack, Typography} from "@mui/material";
import CustomTextField from "../../../components/formValidation/CustomTextField";

const NewColleague = ({reference, obj}) => {
    const [data, setData] = useState({
        email: obj?.email || ''
    })

    const [errors, setErrors] = useState({
        email: false
    })

    const validators = {
        email: [validateRequired, validateEmail]
    }
    return <ErrorHandlingForm data={data} setData={setData} reference={reference} validators={validators} setErrors={setErrors} errors={errors}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Meghívandó kolléga e-mail címe*</Typography>
            <CustomTextField
                errors={errors}
                setErrors={setErrors}
                data={data}
                setData={setData}
                validators={validators}
                name='email'
                variant='standard'
            />
        </Stack>

    </ErrorHandlingForm>
}

export default NewColleague