import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import _ from "lodash";
import {useEffect, useRef, useState} from "react";
import useCallDataApi from "../../../hooks/data";
import WorkHourSelector from "./WorkHourSelector";
import {Prompt} from "react-router-dom";
import Loading from "../../../components/Loading";
import WorkHourPicker from "../../../components/WorkHourPicker";
import OnlyOpenAtPicker from "../../../components/OnlyOpenAtPicker";

const WorkHours = ({userId}) => {
    const [loading, setLoading] = useState(false)
    const [editedOpeningHours, setEditedOpeningHours] = useState([])
    const [openingHours, setOpeningHours] = useState([])
    const modeRef = useRef(null)

    const {
        getData: fetchOpeningHours,
        updateData: updateOpeningHours,
    } = useCallDataApi('work-hour')

    useEffect(() => {
        setLoading(true)
        fetchOpeningHours(`${userId}/get_for_user`).then(o => {
            setOpeningHours(o)
            setEditedOpeningHours(_.cloneDeep(o))
        }).finally(() => setLoading(false))
    }, [])

    const hasUnsavedChanges = JSON.stringify(editedOpeningHours) !== JSON.stringify(openingHours)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault()
                event.returnValue = "" // Required for modern browsers
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);


    return <div className="white-card">
        <Typography variant='subtitle1'>
            Munkaidő beállítása
        </Typography>
        <Typography variant='body2' color='#536B74'>
            Add meg mikor dolgozik a kolléga! Figyelj, hogy a
            munkaidők a nyitvatartási időbe essenek bele!
        </Typography>
        <Typography variant='body2' color='#ff0000'>
            Ez a menüpont az adott kolléga elérhetőségi idejét állítja a foglaló felületen, amennyiben a
            szalonod/boltod nyitvatartását szeretnéd módosítani a lábléc szekcióban azt a <a
            href='/opening-hours'>nyitvatartás</a> menüpontban tudod megtenni.
        </Typography>
        {!loading && <WorkHourSelector
            reference={modeRef}
            data={editedOpeningHours}
            setData={setEditedOpeningHours}
        />}
        {openingHours?.mode === 'open_in_worktime' && editedOpeningHours?.mode === 'open_in_worktime' &&
            <WorkHourPicker editedOpeningHours={editedOpeningHours} setEditedOpeningHours={setEditedOpeningHours}/>}
        {openingHours?.mode === 'open_only_at' && editedOpeningHours?.mode === 'open_only_at' &&
            <OnlyOpenAtPicker editedOpeningHours={editedOpeningHours} setEditedOpeningHours={setEditedOpeningHours} userId={userId} setOpeningHours={setOpeningHours}/>}
        <Stack direction='row' marginTop={2} justifyContent='end'>
            <Button
                size='large'
                variant='contained'
                endIcon={<SaveRoundedIcon/>}
                disabled={!hasUnsavedChanges}
                onClick={() => updateOpeningHours(`${userId}/update_user/`, editedOpeningHours).then(o => {
                    setOpeningHours(o)
                    setEditedOpeningHours(_.cloneDeep(o))
                })}>
                Változtatások mentése
            </Button>
        </Stack>


        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />

        <Loading isLoading={loading}/>
    </div>
}

export default WorkHours