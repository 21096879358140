import Typography from "@mui/material/Typography";
import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import CustomSelect from "../../../components/formValidation/CustomSelect";
import {validateRequired} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";


const General = ({reference, data, setData, canEditRole}) => {

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        title: false,
        role: false,
    });

    const validators = {
        first_name: [validateRequired,],
        last_name: [validateRequired,],
        title: [validateRequired,],
    }
    if (canEditRole) validators['role'] = [validateRequired]


    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>

            <Typography variant='caption' color='primary.main'>
                Vezetéknév*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='last_name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Kersztnév*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='first_name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Titulus/Foglalkozás*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='title'
                variant='standard'
            />

            {canEditRole && <>
                <Typography variant='caption' color='primary.main'>
                    Szerepkör*
                </Typography>
                <CustomSelect
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='role'
                    variant='standard'
                >
                    <MenuItem value='employee'>Alkalmazott</MenuItem>
                    <MenuItem value='manager'>Menedzser</MenuItem>
                </CustomSelect>
            </>}
        </Stack>
    </ErrorHandlingForm>
}

export default General