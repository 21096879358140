import Base from "./Base";
import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import IconButton from "@mui/material/IconButton";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import {useSnackbar} from "notistack";
import JSConfetti from 'js-confetti'


const ActivationLogin = () => {
    const theme = useTheme()
    const {loginUser} = useContext(AuthContext);
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    const [passwordVisible, setPasswordVisible] = useState(false)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        enqueueSnackbar('Sikeresen aktiváltad a fiókodat!', {variant: 'success'})
        const jsConfetti = new JSConfetti()

        jsConfetti.addConfetti()
    }, [])

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color='primary.600'>Bejelentkezés</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color='primary.main'>E-mail cím</Typography>
                            <TextField value={data?.email} onChange={e => setData({...data, email: e.target.value})} name="email" variant='standard'/>
                            <Typography variant="caption" color='primary.main'>Jelszó</Typography>
                            <TextField value={data?.password} onChange={e => setData({...data, password: e.target.value})} name="password" type={passwordVisible ? "text" : "password"} variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon /> : <RemoveRedEyeRoundedIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                        </Stack>
                        <Link to="/forgot-password" style={{color: theme.palette.primary[600]}}>Elfelejtetted a jelszavad?</Link>
                        <Button variant="contained" onClick={() => loginUser(data)}>Bejelentkezés</Button>
                        <Link to="/register" style={{color: theme.palette.primary[600]}}>Nincs még felhasználói fiókod? Regisztrálj!</Link>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default ActivationLogin