import {useState} from "react";
import {validatePassword, validateRequired} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../components/formValidation/CustomTextField";

const PasswordReset = ({reference}) => {
    const [data, setData] = useState({
        old_password: '',
        new_password: '',
        new_again: '',
    })

    const [errors, setErrors] = useState({
        old_password: false,
        new_password: false,
        new_again: false,
    })

    const validators = {
        old_password: [validateRequired,],
        new_password: [validateRequired, validatePassword],
        new_again: [validateRequired, validatePassword],
    }

    const validateBothPasswordMatch = value => {
        if (value.new_password !== value.new_again) return {
            valid: false,
            error: 'Nem egyezik az új jelszó és a megerősítés!'
        }
        return {
            valid: true,
            error: false
        }
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
        reference={reference}
        customValidators={[validateBothPasswordMatch]}
    >
        <Stack spacing={1} marginTop={2} marginBottom={2}>

            <Typography variant='caption' color='primary.main'>
                Régi jelszó*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='old_password'
                variant='standard'
                type='password'
            />

            <Typography variant='caption' color='primary.main'>
                Új jelszó*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='new_password'
                variant='standard'
                type='password'
            />

            <Typography variant='caption' color='primary.main'>
                Új jelszó mégegyszer*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='new_again'
                variant='standard'
                type='password'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default PasswordReset