import Typography from "@mui/material/Typography";
import {Avatar, Stack} from "@mui/material";
import thousandSeparator from "../../../utils/numberUtils";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DinoDialog from "../../../components/Dialog";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../../hooks/data";
import {useParams} from "react-router-dom";
import PriceData from "./PriceData";
import ConfigContext from "../../../context/ConfigContext";
import Loading from "../../../components/Loading";

const Services = () => {
    const editPriceForm = useRef(null)
    const newPriceForm = useRef(null)
    const params = useParams()
    const {
        getData: fetchPrice,
        createData: createPrice,
        deleteData: deletePrice,
        updateData: updatePrice
    } = useCallDataApi('price')
    const {getData: fetchService} = useCallDataApi('service')
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(-1)
    const [services, setServices] = useState([])
    const [selectableServices, setSelectableServices] = useState([])
    const [prices, setPrices] = useState([])
    const [priceDeleteDialog, setPriceDeleteDialog] = useState(false)
    const [priceEditDialog, setPriceEditDialog] = useState(false)
    const [priceDialog, setPriceDialog] = useState(false)
    const {baseUrl} = useContext(ConfigContext)

    useEffect(() => {
        setLoading(false)
        Promise.all([
            fetchPrice(`get_for_colleague?colleague=${params?.id}`),
            fetchService(`get_for_business`)
        ])
            .then(([pr, se]) => {
                setServices(se)
                setPrices(pr)
                const usedServices = pr.map(p => p.service)
                setSelectableServices(se.filter(s => !usedServices.includes(s.id)))
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const newPrice = () => {
        const data = newPriceForm.current.getData()
        if (data) {
            setPriceDialog(false)
            setLoading(true)
            createPrice({...data, colleague: params.id})
                .then(p => {
                    setPrices([...prices, p])
                })
                .finally(() => setLoading(false))
        }

    }

    const editPrice = () => {
        const data = editPriceForm.current.getData()
        if (data) {
            setPriceEditDialog(false)
            setLoading(true)
            updatePrice(current, data)
                .then(updatedPrice => setPrices(prices.map((price) =>
                    price.id === updatedPrice.id ? updatedPrice : price
                )))
                .finally(() => setLoading(false))
        }
    }

    const removePrice = () => {
        setPriceDeleteDialog(false)
        deletePrice(current).then(() => setPrices(prices.filter(p => p.id !== current)))
    }

    const findPrice = id => prices.find(p => p.id === id)

    const findService = id => services.find(s => s.id === id)

    return <div className="white-card">
        <Typography variant='subtitle1'>Szolgáltatások</Typography>
        <Typography variant='body2' color='#536B74'>Add meg kolléga által nyújtott
            szolgáltatásokat!</Typography>
        <Stack spacing={2} marginTop={2}>
            {prices.map((p, i) => <Stack direction='row' key={`price_${i}`}
                                         justifyContent="space-between">
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Avatar src={baseUrl + findService(p?.service)?.image}
                            sx={{width: '48px', height: '48px'}}/>
                    <Stack>
                        <Typography variant='subtitle2'>{findService(p?.service)?.name}</Typography>
                        <Typography variant='caption'
                                    color='#536B74'>{findService(p?.service)?.length} perc</Typography>
                    </Stack>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1} alignItems='center'>
                    <Typography
                        variant='caption'
                        color='#2F5C43'>
                        {thousandSeparator(p.price)} Ft
                    </Typography>
                    <Stack direction='row' spacing={1}>
                        <IconButton onClick={() => {
                            setCurrent(p.id)
                            setPriceEditDialog(true)
                        }} sx={{
                            border: '2px solid',
                            borderColor: 'primary.main',
                            background: '#fff',
                            color: 'primary.main'
                        }}>
                            <ModeEditOutlineRoundedIcon/>
                        </IconButton>
                        <IconButton onClick={() => {
                            setCurrent(p.id)
                            setPriceDeleteDialog(true)
                        }} sx={{
                            border: '2px solid',
                            borderColor: 'primary.main',
                            background: '#fff',
                            color: 'primary.main'
                        }}>
                            <DeleteRoundedIcon/>
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>)}
        </Stack>
        <Stack direction={{xs: 'column', sx: 'row', md: 'row'}} marginTop={2} spacing={2}>
            <Button
                variant='contained'
                disabled={selectableServices.length === 0}
                onClick={() => setPriceDialog(true)}
                sx={{alignSelf: 'end'}}
                endIcon={<AddCircleIcon/>}
            >
                Új szolgáltatás hozzáadása
            </Button>
        </Stack>

        <DinoDialog open={priceDeleteDialog}
                    title='Ár törlése'
                    subtitle='Ha kitörlöd az árat a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setPriceDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={removePrice} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceEditDialog}
                    title='Ár szerkesztése'
                    subtitle='Add meg az új árat!'
                    handleClose={() => setPriceEditDialog(false)}>
            <PriceData reference={editPriceForm} obj={findPrice(current)} prices={prices}/>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={editPrice} endIcon={<SaveRoundedIcon/>}>
                    Változtatások mentése
                </Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceDialog}
                    title='Új szolgáltatás hozzárendelése'
                    subtitle='Add meg az új árat!'
                    handleClose={() => setPriceDialog(false)}>
            <PriceData reference={newPriceForm} prices={prices}/>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={newPrice} endIcon={<SaveRoundedIcon/>}>
                    Változtatások mentése
                </Button>
            </Stack>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Services