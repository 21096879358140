import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {validateTimeValue, validateDate, validateRequired} from "../../../components/formValidation/validators";
import CustomDatePicker from "../../../components/formValidation/CustomDatePicker";
import CustomTimePicker from "../../../components/formValidation/CustomTimePicker";

const WorkDayData = ({reference, obj}) => {
    const [data, setData] = useState({
        day: new Date() || obj?.day,
        open_from: new Date() || obj?.open_from,
        open_until: new Date() || obj?.open_until,
    })

    const [errors, setErrors] = useState({
        day: false,
        open_from: false,
        open_until: false,
    })

    const validators = {
        day: [validateRequired, validateDate],
        open_from: [validateRequired, validateTimeValue],
        open_until: [validateRequired, validateTimeValue],
    }

    const isOpenFromBeforeOpenUntil = (obj) => {
        const { open_from, open_until } = obj;
        const openFromTime = open_from.getHours() * 60 + open_from.getMinutes();
        const openUntilTime = open_until.getHours() * 60 + open_until.getMinutes();
        return {valid: openFromTime < openUntilTime, error: 'Nem lehet nagyobb a kezdete a végénél! Válassz másik időpontot!'}
    }

    return <ErrorHandlingForm reference={reference} data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} customValidators={[isOpenFromBeforeOpenUntil]}>
        <Stack spacing={1}>
            <Typography variant='caption'>Dátum*</Typography>
            <CustomDatePicker
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='day'
                disablePast
            />
            <Typography variant='caption'>Kezdete*</Typography>
            <CustomTimePicker
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='open_from'
            />
            <Typography variant='caption'>Vége*</Typography>
            <CustomTimePicker
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='open_until'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default WorkDayData