import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import CompanyBilling from "../../authentication/components/CompanyBilling";
import PersonBilling from "../../authentication/components/PersonBilling";

const InvoiceData = ({editedBusiness, setEditedBusiness, companyBillingRef, personBillingRef}) => {
    return <><FormControl>
        <RadioGroup
            name="controlled-radio-buttons-group"
            value={editedBusiness?.incorporation_type}
            onChange={(event => {
                setEditedBusiness({...editedBusiness, incorporation_type: event.target.value})
            })}
            sx={{my: 1}}
        >
            <FormControlLabel
                value="company"
                control={<Radio/>}
                label={<Typography
                    color='#6F8E9A'
                    variant='caption'>
                    Vállalkozás
                </Typography>}/>

            <FormControlLabel
                value="taxable_person"
                control={<Radio/>}
                label={<Typography
                    color='#6F8E9A'
                    variant='caption'>
                    Magánszemély
                </Typography>}/>
        </RadioGroup>
    </FormControl>
        {editedBusiness?.incorporation_type === 'company' ?
            <CompanyBilling
                data={editedBusiness}
                setData={setEditedBusiness}
                reference={companyBillingRef}
            />
            :
            <PersonBilling
                data={editedBusiness}
                setData={setEditedBusiness}
                reference={personBillingRef}
            />
        }
    </>
}

export default InvoiceData