import AuthBase from "../AuthBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {Avatar, Grid, Stack} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ConfigContext from "../../context/ConfigContext";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {Link} from "react-router-dom";
import DinoDialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import AuthContext from "../../context/AuthContext";
import NewColleague from "./components/NewColleague";
import {useSnackbar} from "notistack";
import {hasPermission} from "../../utils/permissionUtils";
import {hasPlan, plans} from "../../utils/subscriptionUtils";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";


const Colleagues = () => {
    const [colleagues, setColleagues] = useState([])
    const [newColleagueOpen, setNewColleagueOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const {getData: fetchColleagues} = useCallDataApi('colleague')
    const {postData: register} = useCallDataApi('')
    const {baseUrl} = useContext(ConfigContext)
    const {getUser} = useContext(AuthContext)
    const newColleagueEmailForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar()
    const user = getUser()


    const buttons = [{
        name: 'Új kolléga hozzáadása',
        props: {
            variant: 'contained',
            endIcon: <AddCircleRoundedIcon/>,
            disabled: !hasPlan(user, plans.MULTIPLE) || !hasPermission(user, "colleague", "create"),
            onClick: () => setNewColleagueOpen(true),

        }
    }]

    const filterButtons = [
        {
            name: 'Új kolléga hozzáadása',
            props: {
                variant: 'contained',
                endIcon: <AddCircleRoundedIcon/>,
                disabled: !hasPlan(user, plans.MULTIPLE) || !hasPermission(user, "colleague", "create"),
                onClick: () => setNewColleagueOpen(true)
            }
        }
    ]

    useEffect(() => {
        setLoading(true)
        fetchColleagues('get_for_business')
            .then(c => setColleagues(c))
            .finally(() => setLoading(false))
    }, [])

    const newColleague = () => {
        const data = newColleagueEmailForm.current.getData()
        if (data) {
            setLoading(true)
            register('register_existing', data)
                .then(r => {
                    setNewColleagueOpen(false)
                    enqueueSnackbar('Email kiküldve!', {variant: 'success'})
                })
                .finally(() => setLoading(false))
        }
    }

    const newColleagueActions = <>
        <Button variant='outlined' onClick={() => setNewColleagueOpen(false)}>mégsem</Button>
        <Button variant='contained' onClick={newColleague} endIcon={<AddCircleOutlineIcon/>}>
            Kolléga hozzáadása
        </Button>
    </>

    return <AuthBase label='Kollégák' buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <Typography variant='subtitle2' style={{color: '#536B74'}}
                        gutterBottom>{colleagues.length} kolléga</Typography>
            <Grid container spacing={2}>
                {colleagues.map((c, i) => <Grid key={`colleage_${i}`} item xs={12} md={3}>
                    <div className="white-card">
                        <Stack spacing={2} alignItems='center'>
                            <Avatar src={baseUrl + c.profile_picture} sx={{width: 90, height: 90}}/>
                            <Typography variant='subtitle2' textAlign='center'>{c.name}</Typography>
                            <Typography variant='body1' color='#536B74'>{c.title}</Typography>
                            <Button
                                variant='outlined'
                                disabled={!hasPermission(user, "colleague", "update", c)}
                                component={Link}
                                to={`/colleague/${c?.id}`}
                                endIcon={<EditRoundedIcon/>}>
                                Szerkesztés
                            </Button>
                        </Stack>
                    </div>
                </Grid>)}
            </Grid>
        </div>

        <DinoDialog open={newColleagueOpen}
                    handleClose={() => setNewColleagueOpen(false)}
                    title='Új kolléga hozzáadása'
                    subtitle='Adja meg a kolléga e-mail címét és küldünk neki egy regisztrációs linket.'
                    actions={newColleagueActions}>
            <NewColleague reference={newColleagueEmailForm} obj={{}}/>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Colleagues