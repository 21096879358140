import AuthBase from "../AuthBase";
import {useContext, useEffect, useRef, useState} from "react";
import {Prompt, useHistory, useParams} from "react-router-dom";
import {
    Avatar, IconButton,
    Stack,
} from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ConfigContext from "../../context/ConfigContext";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ExtraItemTemplate from "./ExtraItemTemplate";

import {truncateText} from "../../utils/FormUtils";
import General from "./components/General";
import Visibility from "./components/Visibility";
import Image from "./components/Image";
import {mergeDataIfNotNull} from "../../utils/misc";
import {useSnackbar} from "notistack";
import Invoice from "./components/Invoice";


const ExtraItemDetail = () => {
    const history = useHistory()
    const {baseUrl} = useContext(ConfigContext)
    const {enqueueSnackbar} = useSnackbar()

    const [extraItem, setExtraItem] = useState(ExtraItemTemplate)
    const [services, setServices] = useState([])
    const [editedExtraItem, setEditedExtraItem] = useState(ExtraItemTemplate)
    const [loading, setLoading] = useState(true)

    const params = useParams()

    const generalRef = useRef(null)
    const visibilityRef = useRef(null)
    const imageRef = useRef(null)
    const invoiceRef = useRef(null)

    const {
        getData: fetchExtraItem,
        postData: postExtraItem,
        updateDataFormData: updateExtraItem
    } = useCallDataApi('extra-items')
    const {getData: fetchService} = useCallDataApi('service')


    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchExtraItem(params.id),
            fetchService('get_for_business/')
        ]).then(([e, s]) => {
            setExtraItem(e)
            setEditedExtraItem(e)
            setServices(s)
        }).finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasUnsavedChanges = JSON.stringify(extraItem) !== JSON.stringify(editedExtraItem)

    const savePageData = () => {
        const generalRefData = generalRef.current.getData();
        const visibilityRefData = visibilityRef.current.getData();
        const imageRefData = imageRef.current.getData();
        const invoiceRefData = invoiceRef.current.getData();
        const refData = [
            generalRefData,
            visibilityRefData,
            imageRefData,
            invoiceRefData
        ]
        const invalidRefs = refData.filter(ref => ref === null)
        if (invalidRefs.length === 0) {
            const allData = mergeDataIfNotNull(refData);
            const isFile = allData?.image instanceof File;
            if (!isFile) delete allData['image']
            setLoading(true)
            updateExtraItem(extraItem?.id, allData).then(s => {
                setExtraItem(s)
                setEditedExtraItem(s)
                enqueueSnackbar('Mentve!', {variant: 'success'})
            }).finally(() => setLoading(false))
        } else {
            enqueueSnackbar('Kérlek javísd a hibákat!', {variant: 'error'})
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = ""; // Required for modern browsers
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            variant: 'contained',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }]

    const addService = (s) => {
        setLoading(true)
        postExtraItem('add_service/', {id: extraItem?.id, service: s?.id}).then(s => {
            setEditedExtraItem({...editedExtraItem, services: s})
            setExtraItem({...extraItem, services: s})
        }).finally(() => setLoading(false))
    }

    const removeService = (s) => {
        setLoading(true)
        postExtraItem('remove_service/', {id: extraItem?.id, service: s?.id}).then(s => {
            setEditedExtraItem({...editedExtraItem, services: s})
            setExtraItem({...extraItem, services: s})
        }).finally(() => setLoading(false))
    }

    return <AuthBase label={extraItem?.name} buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.push('/extra-items')}>vissza</Button>
            <Masonry columns={{xs: 1, sm: 1, md: 2, xl: 2}} spacing={2} sx={{margin: 0}}>
                {/*General data*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Általános adatok</Typography>
                    <Typography variant='body2' color='#536B74'>Add meg a tétel nevét és leírását!</Typography>
                    <General reference={generalRef} data={editedExtraItem} setData={setEditedExtraItem}/>
                </div>

                {/*/!*Visibility*!/*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Láthatóság</Typography>
                    <Typography variant='body2' color='#536B74'>Ha nem szeretnéd, hogy a tétel látható legyen
                        a kliensek számára akkor kikapcsolhatod. (Az elrejtés előtt leadott foglalások nem fognak
                        törlődni!)</Typography>
                    <Visibility reference={visibilityRef} data={editedExtraItem} setData={setEditedExtraItem}/>
                </div>

                {/*/!*Picture*!/*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Tétel képe</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Tölts fel egy a tételre jellemző képet!
                    </Typography>
                    <Image reference={imageRef} data={editedExtraItem} setData={setEditedExtraItem}/>
                </div>

                {/*/!*Invoice*!/*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Számlázási beállítások</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Írd be a számlázásnál hogyan kezeljük a terméket!
                    </Typography>
                    <Invoice reference={invoiceRef} data={editedExtraItem} setData={setEditedExtraItem}/>
                </div>

                {/*Services*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatások</Typography>
                    <Typography variant='body2' color='#536B74'>
                        Vedd fel azokat a szolgáltatásokat ahol szeretnéd, hogy elérhető legyen a tétel!
                    </Typography>
                    <Stack spacing={2} marginTop={2}>
                        {services.map((s, i) => <Stack direction='row' key={`price_${i}`}
                                                       justifyContent="space-between">
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Avatar src={baseUrl + s?.image}
                                        sx={{width: '48px', height: '48px'}}/>
                                <Stack>
                                    <Typography variant='subtitle2'>{s.name}</Typography>
                                    <Typography variant='caption' color='#536B74'>
                                        {truncateText(s.description, 150)}
                                    </Typography>
                                </Stack>
                            {/*</Stack>*/}
                            {/*<Stack direction='row' spacing={1} alignItems='center'>*/}
                                {editedExtraItem?.services?.includes(s?.id) ?
                                    <IconButton
                                        sx={{
                                            border: '2px solid',
                                            borderColor: 'primary.main',
                                            background: '#fff',
                                            color: 'primary.main'
                                        }}
                                        variant='outlined'
                                        onClick={() => removeService(s)}>
                                        <RemoveRoundedIcon/>
                                    </IconButton>
                                    :
                                    <IconButton
                                        sx={{
                                            border: '2px solid',
                                            borderColor: 'primary.main',
                                            background: '#fff',
                                            color: 'primary.main'
                                        }}
                                        variant='contained'
                                        onClick={() => addService(s)}>
                                        <AddRoundedIcon/>
                                    </IconButton>
                                }
                            </Stack>
                        </Stack>)}
                    </Stack>

                </div>

            </Masonry>

        </div>

        <Loading isLoading={loading}/>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />
    </AuthBase>

}

export default ExtraItemDetail