import {MenuItem, Stack} from "@mui/material";
import {useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import CustomSelect from "../../../../components/formValidation/CustomSelect";
import Typography from "@mui/material/Typography";
import {validateRequired} from "../../../../components/formValidation/validators";

const MaxDate = ({reference, data, setData}) => {
    const [errors, setErrors] = useState({
        accept_until: false,
        accept_after: false,
    });

    const validators = {
        accept_until: [validateRequired],
        accept_after: [validateRequired],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Foglalások fogadása ettől
            </Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='accept_after'
                variant='standard'
            >
                <MenuItem value={0}>Azonnal</MenuItem>
                <MenuItem value={1}>1 óra múlva</MenuItem>
                <MenuItem value={2}>2 óra múlva</MenuItem>
                <MenuItem value={3}>3 óra múlva</MenuItem>
                <MenuItem value={4}>4 óra múlva</MenuItem>
                <MenuItem value={5}>5 óra múlva</MenuItem>
                <MenuItem value={24}>1 nap múlva</MenuItem>
                <MenuItem value={48}>2 nap múlva</MenuItem>
                <MenuItem value={72}>3 nap múlva</MenuItem>
                <MenuItem value={-1}>Holnaptól</MenuItem>
            </CustomSelect>

            <Typography variant='caption' color='primary.main'>
                Foglalások fogadása eddig
            </Typography>
            <CustomSelect
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='accept_until'
                variant='standard'
            >
                <MenuItem value={30}>30 nap</MenuItem>
                <MenuItem value={60}>60 nap</MenuItem>
                <MenuItem value={180}>180 nap</MenuItem>
                <MenuItem value={365}>365 nap</MenuItem>
            </CustomSelect>
        </Stack>
    </ErrorHandlingForm>
}

export default MaxDate