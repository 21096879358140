import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateRequired} from "../../../components/formValidation/validators";

const SimpleData = ({reference, obj}) => {
    const [data, setData] = useState({
        name: obj?.name || '',
        simple_merchant_id: obj?.simple_merchant_id || '',
        simple_secret_key: obj?.simple_secret_key || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        simple_merchant_id: false,
        simple_secret_key: false,
    })

    const validators = {
        name: [validateRequired, ],
        simple_merchant_id: [validateRequired, ],
        simple_secret_key: [validateRequired, ],
    }

    return <ErrorHandlingForm
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        reference={reference}
        validators={validators}
    >
        <Stack spacing={1}>
            <Typography id="email-label" variant="caption" color='primary.main'>
                Név*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="name"
                variant="standard"
                inputProps={{
                    "aria-labelledby": "email-label",
                    "aria-required": "true",
                }}
            />
            <Typography id="email-label" variant="caption" color='primary.main'>
                Kereskedői azonosító*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="simple_merchant_id"
                variant="standard"
                inputProps={{
                    "aria-labelledby": "email-label",
                    "aria-required": "true",
                }}
            />
            <Typography id="email-label" variant="caption" color='primary.main'>
                Titkosító kulcs*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="simple_secret_key"
                variant="standard"
                inputProps={{
                    "aria-labelledby": "email-label",
                    "aria-required": "true",
                }}
            />
        </Stack>
    </ErrorHandlingForm>
}

export default SimpleData