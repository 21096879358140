import {InputAdornment, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateMaxLength, validateRequired} from "../../../components/formValidation/validators";
import colleagues from "../../../assets/Colleagues section.png";

const Colleagues = ({reference, disabled, data, setData}) => {
    const [errors, setErrors] = useState({
        colleagues_heading: false,
        colleagues_text: false,
    });

    const validators = {
        colleagues_heading: [validateRequired, value => validateMaxLength(255, value)],
        colleagues_text: [validateRequired, ],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference}>
        <Stack direction={{sm: 'column', lg: 'row'}} spacing={2} marginTop={3} marginBottom={2} sx={{
            '& > *': {
                flexBasis: {
                    sm: '100%', // Full width in column direction
                    lg: '50%' // Half width in row direction
                },
            },
        }}>
            <Stack spacing={1}>
                <Typography variant='caption' color='primary.main'>Címsor</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='colleagues_heading'
                    variant='standard'
                    disabled={disabled}
                />
                <Typography variant='caption' color='primary.main'>Alcím</Typography>
                <CustomTextField
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='colleagues_text'
                    variant='standard'
                    disabled={disabled}
                    multiline
                    rows={4}
                />
            </Stack>
            <img src={colleagues} alt="kontakt" style={{ width: '100%' }} />
        </Stack>
    </ErrorHandlingForm>
}

export default Colleagues;