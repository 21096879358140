import {useContext, useEffect, useState} from "react";
import {Box, Typography, Button, TextField, Divider, IconButton, Avatar} from "@mui/material";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";
import thousandSeparator from "../../../utils/numberUtils";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfigContext from "../../../context/ConfigContext";

const SelectProduct = ({ data, setData }) => {
    const { getData: fetchProduct } = useCallDataApi("extra-items");
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const {baseUrl} = useContext(ConfigContext);
    // const [selectedProductId, setSelectedProductId] = useState(null);
    // const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        setLoading(true);
        fetchProduct("get_for_business")
            .then((p) => setProducts(p))
            .finally(() => setLoading(false));
    }, []);

    const handleAddProduct = (productId, quantity) => {
        if (!productId || quantity <= 0) {
            return;
        }

        const productToAdd = products.find((product) => product.id === productId);
        if (!productToAdd) return;

        const existingProductIndex = (data.ordered_products || []).findIndex(
            (item) => item.id === productId
        );

        const newOrderedProducts = [...(data.ordered_products || [])];

        if (existingProductIndex !== -1) {
            // Update quantity if product already exists
            newOrderedProducts[existingProductIndex].quantity += quantity;
        } else {
            // Add new product
            newOrderedProducts.push({ ...productToAdd, quantity });
        }

        setData({ ...data, ordered_products: newOrderedProducts });
    };

    const handleChangeQuantity = (productId, change) => {
        const newOrderedProducts = data.ordered_products.map((product) =>
            product.id === productId
                ? { ...product, quantity: Math.max(1, product.quantity + change) }
                : product
        );
        setData({ ...data, ordered_products: newOrderedProducts });
    };

    const handleRemoveProduct = (productId) => {
        const newOrderedProducts = data.ordered_products.filter((product) => product.id !== productId);
        setData({ ...data, ordered_products: newOrderedProducts });
    };

    return (
        <Box>
            <Typography variant="h6" mb={2}>
                Válassz a termékek közül
            </Typography>
            <Box>
                {products.map((product) => (
                    // <Box key={product.id} display="flex" alignItems="center" mb={2}>
                    //     <Button
                    //         variant="outlined"
                    //         onClick={() => setSelectedProductId(product.id)}
                    //         fullWidth
                    //     >
                    //         {product.name} - {thousandSeparator(product.price)} Ft
                    //     </Button>
                    // </Box>
                    <Box
                        key={product.id}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Box display='flex' justifyContent="space-between" alignItems='center' gap={1}>
                            <Avatar src={baseUrl + product?.image}/>
                            <Typography variant="body1">
                                {product.name}
                                {/*({product.quantity} db) - Összesen:{" "}*/}
                                {/*{thousandSeparator(product.price * product.quantity)} Ft*/}
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            {/*<IconButton*/}
                            {/*    onClick={() => handleChangeQuantity(product.id, -1)}*/}
                            {/*    disabled={product.quantity <= 1}*/}
                            {/*>*/}
                            {/*    <RemoveIcon />*/}
                            {/*</IconButton>*/}
                            {/*<Typography variant="body2" mx={1}>*/}
                            {/*    {product.quantity}*/}
                            {/*</Typography>*/}
                            <IconButton
                                onClick={() => handleAddProduct(product.id, 1)}
                            >
                                <AddIcon />
                            </IconButton>
                            {/*<IconButton*/}
                            {/*    onClick={() => handleRemoveProduct(product.id)}*/}
                            {/*    sx={{ ml: 2 }}*/}
                            {/*>*/}
                            {/*    <DeleteIcon color="error" />*/}
                            {/*</IconButton>*/}
                        </Box>
                    </Box>
                ))}
            </Box>

            {/*<Box mt={2}>*/}
            {/*    <TextField*/}
            {/*        type="number"*/}
            {/*        variant="standard"*/}
            {/*        value={quantity}*/}
            {/*        onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}*/}
            {/*        inputProps={{ min: 1 }}*/}
            {/*        fullWidth*/}
            {/*    />*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        onClick={handleAddProduct}*/}
            {/*        sx={{ mt: 2 }}*/}
            {/*        fullWidth*/}
            {/*    >*/}
            {/*        Hozzáadás*/}
            {/*    </Button>*/}
            {/*</Box>*/}

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" mb={2}>
                Kiválasztott termékek
            </Typography>
            {data.ordered_products && data.ordered_products.length > 0 ? (
                <Box>
                    {data.ordered_products.map((product) => (
                        <Box
                            key={product.id}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                        >
                            <Box>
                                <Typography variant="body1">
                                    {product.name} ({product.quantity} db) - Összesen:{" "}
                                    {thousandSeparator(product.price * product.quantity)} Ft
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                <IconButton
                                    onClick={() => handleChangeQuantity(product.id, -1)}
                                    disabled={product.quantity <= 1}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <Typography variant="body2" mx={1}>
                                    {product.quantity}
                                </Typography>
                                <IconButton
                                    onClick={() => handleChangeQuantity(product.id, 1)}
                                >
                                    <AddIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => handleRemoveProduct(product.id)}
                                    sx={{ ml: 2 }}
                                >
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                    <Typography variant="body1">Összesen: {thousandSeparator(data?.ordered_products?.reduce((acc, curr) => acc + (curr?.price * curr?.quantity), 0))} Ft</Typography>
                </Box>
            ) : (
                <Typography variant="body1">Nincs kiválasztott termék.</Typography>
            )}

            <Loading isLoading={loading} />
        </Box>
    );
};

export default SelectProduct;
