import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {useState} from "react";
import {
    validateMaxLength,
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";


const General = ({reference, data, setData}) => {
    
    const [errors, setErrors] = useState({
        name: false,
        description: false,
        price: false,
    });

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        description: [validateRequired,],
        price: [validateRequired, validateNumber, validateNonNegativeNumber],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Tétel neve (max. 255 karakter)*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='name'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Tétel rövid leírása
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='description'
                variant='standard'
            />

            <Typography variant='caption' color='primary.main'>
                Tétel ára*
            </Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='price'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default General