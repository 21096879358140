import Base from "./Base";
import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import IconButton from "@mui/material/IconButton";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../components/formValidation/CustomTextField";
import {validatePassword, validateRequired} from "../../components/formValidation/validators";


const NewPassword = () => {
    const {newPassword} = useContext(AuthContext);
    const {slug} = useParams()
    const formRef = useRef(null)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [data, setData] = useState({
        password: '',
        password_again: ''
    })

    const [errors, setErrors] = useState({
        password: false,
        password_again: false,
    })

    const validators = {
        password: [validateRequired, validatePassword],
        password_again: [validateRequired, validatePassword],
    }

    const validatePasswordMatch = data => {
        const isPasswordSet = !!data?.password
        const match = data?.password === data?.password_again
        return {
            valid: isPasswordSet && match,
            error: 'A két jelszó nem egyezik meg!'
        }
    }

    const handleNewPassword = () => {
        const data = formRef.current.getData()
        if (data) newPassword({...data, slug: slug})
    }

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <Stack spacing={{xs: 2}}>
                    <Typography variant="headline6" color='primary.600'>
                        Jelszó megváltoztatása
                    </Typography>
                    <ErrorHandlingForm
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        reference={formRef}
                        customValidators={[validatePasswordMatch]}
                    >
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <input type="hidden" name='slug' value={slug}/>
                            <Typography variant="caption" color='primary.main'>
                                Új jelszó
                            </Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                variant='standard'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            edge="end"
                                        >
                                            {passwordVisible ? <VisibilityOffRoundedIcon/> : <RemoveRedEyeRoundedIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                }}/>
                            <Typography variant="caption" color='primary.main'>
                                Új jelszó mégegyszer
                            </Typography>
                            <CustomTextField
                                data={data}
                                setData={setData}
                                errors={errors}
                                setErrors={setErrors}
                                validators={validators}
                                name="password_again"
                                type={passwordVisible ? "text" : "password"}
                                variant='standard'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                            edge="end"
                                        >
                                            {passwordVisible ? <VisibilityOffRoundedIcon/> :
                                                <RemoveRedEyeRoundedIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                }}/>
                        </Stack>
                    </ErrorHandlingForm>
                    <Button variant="contained" onClick={handleNewPassword}>Jelszó megváltoztatása</Button>
                </Stack>

            </div>
        </div>
    </Base>
}

export default NewPassword
