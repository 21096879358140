import Typography from "@mui/material/Typography";
import {Button, Grid, Stack, useTheme} from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import thousandSeparator from "../../../utils/numberUtils";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import {useEffect, useState} from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DinoDialog from "../../../components/Dialog";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";

const Subscription = ({business, setBusiness, setEditedBusiness}) => {
    const [loading, setLoading] = useState(false)
    const [price, setPrice] = useState({})
    const {getData: fetchSettings} = useCallDataApi('general')
    const {updateData: updateBusiness} = useCallDataApi('business')
    const theme = useTheme()

    useEffect(() => {
        setLoading(true)
        fetchSettings('get_price')
            .then(p => setPrice(p))
            .finally(() => setLoading(false))
    }, [])

    const modifySubscription = (type) => {
        updateBusiness(`${business?.id}/`, {next_months_plan: type}).then(r => {
            setEditedBusiness(r)
            setBusiness(r)
        })
    }

    const [deleteSubscriptionDialogOpen, setDeleteSubscriptionDialogOpen] = useState(false)
    const subscriptionMap = {
        'none': 'Lemondva',
        'one': 'Egyszemélyes csomag',
        'multiple': 'Többszemélyes csomag'
    }
    const subscriptionDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setDeleteSubscriptionDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteSubscriptionDialogOpen(false)
            modifySubscription('none')
        }} endIcon={<DeleteForeverRoundedIcon/>}>Előfizetés lemondása</Button>
    </>
    return <div className="white-card">
        <Typography variant='subtitle2'>Előfizetési csomag</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>Előfizetési csomag váltása a havi
            fordulóponttól lehetséges. (Következő: {business.next_payment})</Typography>
        {business.next_months_plan !== business.subscription &&
            <Typography variant='body2' color='#536B74' gutterBottom>Következő
                hónaptól: {subscriptionMap[business.next_months_plan]}</Typography>}
        <Grid container spacing={1} marginBottom={2} marginTop={2}>
            <Grid item xs={12}>
                <Stack direction='row' spacing={2} sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary[50],
                    borderRadius: '16px',
                    padding: '16px 24px'
                }}>
                    <AccountCircleRoundedIcon
                        sx={{color: theme.palette.primary.main, fontSize: '50px'}}/>
                    <Stack spacing={1}>
                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Egyszemélyes
                            vállalkozói csomag</Typography>
                        <Typography variant='body2' color='#38474D'>Ideális egy személyes
                            vállalkozásoknak ahol egy szolgáltató végzi a munkát.</Typography>
                        <Typography variant='subtitle2'
                                    color={theme.palette.primary[700]}>{thousandSeparator(price.one_price)} Ft/hó</Typography>
                        <Button endIcon={<CurrencyExchangeRoundedIcon/>} onClick={() => modifySubscription('one')}
                                disabled={business?.next_months_plan === 'one'} variant='contained'
                                sx={{
                                    padding: '15px 12px 15px 16px',
                                    width: 'fit-content'
                                }}>{business?.next_months_plan === 'one' ? 'Előfizetési csomag aktív' : 'Előfizetési csomagot váltok'}</Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row' spacing={2} sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary[50],
                    borderRadius: '16px',
                    padding: '16px 24px'
                }}>
                    <PeopleRoundedIcon sx={{color: theme.palette.primary.main, fontSize: '50px'}}/>
                    <Stack spacing={1}>
                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Többszemélyes
                            vállalkozói csomag</Typography>
                        <Typography variant='body2' color='#38474D'>Ideális több fős vállalkozásoknak
                            ahol eltérő személyekhez lehet időpontot foglalni.</Typography>
                        <Typography variant='subtitle2'
                                    color={theme.palette.primary[700]}>{thousandSeparator(price.multiple_price)} Ft/hó</Typography>
                        <Button endIcon={<CurrencyExchangeRoundedIcon/>} onClick={() => modifySubscription('multiple')}
                                disabled={business?.next_months_plan === 'multiple'} variant='contained'
                                sx={{
                                    padding: '15px 12px 15px 16px',
                                    width: 'fit-content'
                                }}>{business?.next_months_plan === 'multiple' ? 'Előfizetési csomag aktív' : 'Előfizetési csomagot váltok'}</Button>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
        <Button onClick={() => setDeleteSubscriptionDialogOpen(true)}>Előfizetés lemondása</Button>

        <DinoDialog open={deleteSubscriptionDialogOpen}
                    handleClose={() => setDeleteSubscriptionDialogOpen(false)}
                    title='Előfizetés lemondása'
                    subtitle='Biztosan lemondja az előfizetést? Az aktuális hónap végével nem fogja tudni használani a rendszert!'
                    actions={subscriptionDeleteActions}>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Subscription