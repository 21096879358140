import {
    Divider,
    FormControlLabel,
    Radio,
    Stack, Tooltip
} from "@mui/material";
import {useContext, useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomRadioGroup from "../../../components/formValidation/CustomRadioGroup";
import CustomSwitch from "../../../components/formValidation/CustomSwitch";
import {hasPlan, plans} from "../../../utils/subscriptionUtils";
import AuthContext from "../../../context/AuthContext";
import DisabledWrapper from "../../../components/DisabledWrapper";


const Invoice = ({reference, data, setData, billingAvailable, paymentAuthorized}) => {
    const {getUser} = useContext(AuthContext)
    const [errors, setErrors] = useState({
        mandatory_billing_details: false,
        automatic_invoice: false,
    });

    const validators = {
        mandatory_billing_details: [],
        automatic_invoice: [],
    }
    const user = getUser()
    const disabledCause = [{
        disabled: !hasPlan(user, plans.MULTIPLE),
        message: 'A jelenlegi csomagod nem tartalmazza ezt a funkciót. Válassz egy nagyobb csomagot a beállításokban a használathoz!'
    }, {
        disabled: !paymentAuthorized,
        message: 'Nincs bekapcsolva az online fizetés! A beállítások menüpontban tudod ezt átállítani.'
    }, {
        disabled: !billingAvailable,
        message: 'Nincs beállítva számlázóprogram vagy nem aktív! A beállítások menüpontban tudod ezt átállítani.'
    }]

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Helyszíni fizetés esetén
            </Typography>
            <CustomRadioGroup
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='mandatory_billing_details'
            >
                <FormControlLabel
                    value='none'
                    control={<Radio/>}
                    label={<Typography variant='caption'>
                        Ne kérjen számlázási adatokat
                    </Typography>}/>
                <FormControlLabel
                    value='optional' control={<Radio/>}
                    label={<Typography variant='caption'>
                        Opcionálisan kitöltheti
                    </Typography>}/>
                <FormControlLabel
                    value='mandatory' control={<Radio/>}
                    label={<Typography variant='caption'>
                        Kötelezően ki kell tölteni
                    </Typography>}/>

            </CustomRadioGroup>

            <Divider/>

            <Typography variant='caption' color='primary.main'>
                sikeres online fizetés esetén
            </Typography>
            <DisabledWrapper disabledCause={disabledCause}>
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name='automatic_invoice'
                    labelOn='Bekapcsolva'
                    labelOff='Kikapcsolva'
                />
            </DisabledWrapper>
        </Stack>
    </ErrorHandlingForm>
}

export default Invoice

