import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import {truncateText} from "../../../utils/misc";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {useState, useRef, useEffect} from "react";
import useCallDataApi from "../../../hooks/data";
import DinoDialog from "../../../components/Dialog";
import ApiKeyData from "./ApiKeyData";
import {useSnackbar} from "notistack";
import Loading from "../../../components/Loading";

const ApiKey = () => {
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(-1)

    const [apiKeys, setApiKeys] = useState([])
    const {getData: fetchApiKeys, postData: postApiKeys, deleteData: deleteApiKey} = useCallDataApi('api-keys')
    const [newApiDialogOpen, setNewApiDialogOpen] = useState(false)
    const [deleteApiDialogOpen, setDeleteApiDialogOpen] = useState(false)
    const apiKeyForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setLoading(true)
        fetchApiKeys()
            .then(a => setApiKeys(a))
            .finally(() => setLoading(false))
    }, [])

    const addApiKey = () => {
        const data = apiKeyForm.current.getData()
        if (data) {
            postApiKeys('', data)
                .then(r => setApiKeys([...apiKeys, r]))
            setNewApiDialogOpen(false)
        }
    }

    const deleteApi = (c) => {
        setLoading(true)
        deleteApiKey(c)
            .then(r => setApiKeys([...apiKeys.filter(a => a?.id !== c)]))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const deleteApiActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteApiDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteApiDialogOpen(false)
            deleteApi(current)
        }} endIcon={<DeleteForeverRoundedIcon/>}>Kulcs törlése</Button>
    </>

    return <div className="white-card">
        <Typography variant='subtitle2'>API Kulcsok</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>
            Az API kulcsokkal biztonságosan
            hozzáférhetsz a DinoBooking különféle funkcióihoz. Kezeld ezeket a kulcsokat bizalmasan a
            rendszer védelme érdekében.
        </Typography>
        <Stack spacing={2} marginBottom={2}>
            {apiKeys?.map(k => <Stack spacing={2} direction='row' key={k?.id} alignItems='center'>
                <Typography
                    variant='body2'
                    color='#536B74'
                    gutterBottom>
                    {truncateText(k?.name, 50)}
                </Typography>
                <Button
                    variant='contained'
                    onClick={() => {
                        navigator.clipboard.writeText(k.key)
                        enqueueSnackbar("Másolva", {variant: "success"})
                    }}
                    endIcon={<ContentCopyRoundedIcon/>}>
                    API kulcs másolása
                </Button>
                <Button
                    onClick={() => {
                        setCurrent(k.id)
                        setDeleteApiDialogOpen(true)
                    }}
                    endIcon={<DeleteForeverRoundedIcon/>}>
                    API kulcs törlése
                </Button>
            </Stack>)}
        </Stack>
        <Button
            variant='contained'
            onClick={() => setNewApiDialogOpen(true)}
            endIcon={<AddCircleRoundedIcon/>}>
            Új API kulcs hozzáadása
        </Button>

        <DinoDialog open={deleteApiDialogOpen}
                    handleClose={() => setDeleteApiDialogOpen(false)}
                    title='Api kulcs törlése'
                    subtitle='Biztosan törli ezt a kulcsot?'
                    actions={deleteApiActions}>
        </DinoDialog>

        <DinoDialog open={newApiDialogOpen}
                    handleClose={() => setDeleteApiDialogOpen(false)}
                    title='Új API kulcs'
                    subtitle='Add meg a nevet, érdemes azt az alkalmazás nevét megadni ahol használni fogod.'
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}}
                                onClick={() => setNewApiDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={addApiKey} endIcon={<AddCircleRoundedIcon/>}>Kulcs hozzáadása</Button>
                    </>}>
            <ApiKeyData reference={apiKeyForm}/>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default ApiKey