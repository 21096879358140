import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {validateRequired} from "../../../components/formValidation/validators";
import CustomRadioGroup from "../../../components/formValidation/CustomRadioGroup";
import {Box, FormControlLabel, Grid, Radio} from "@mui/material";
import Typography from "@mui/material/Typography";

const ProviderData = ({reference, data, setData}) => {

    const [errors, setErrors] = useState({
        billing_service: false,
    })

    const validators = {
        billing_service: [validateRequired, ],
    }

    return <ErrorHandlingForm
        reference={reference}
        data={data}
        setData={setData}
        errors={errors}
        setErrors={setErrors}
        validators={validators}
    >
        <CustomRadioGroup
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
            validators={validators}
            variant='standard'
            name='billing_service'
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{padding: '32px'}} className={data?.billing_service === 'billingo' && 'active-radio'}>
                        <FormControlLabel value="billingo" control={<Radio/>}
                                          label="Billingo"/>
                        <Typography sx={{color: '#536B74'}} variant='body2'>Abban az esetben válaszd ezt az opciót,
                            ha rendelkezel api hozzáféréssel a billingo fiókodhoz.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{padding: '32px'}} className={data?.billing_service === 'szamlazz' && 'active-radio'}>
                        <FormControlLabel value="szamlazz" control={<Radio/>}
                                          label="Számlázz.hu"/>
                        <Typography sx={{color: '#536B74'}} variant='body2'>Abban az esetben válaszd ezt az opciót,
                            ha rendelkezel api hozzáféréssel a számlázz.hu fiókodhoz.</Typography>
                    </Box>
                </Grid>
            </Grid>

        </CustomRadioGroup>
    </ErrorHandlingForm>
}

export default ProviderData;