import Typography from "@mui/material/Typography";
import {Button, Grid, Stack, useTheme} from "@mui/material";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import thousandSeparator from "../../../utils/numberUtils";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import {useEffect, useState} from "react";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";

const SmsTokens = () => {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [tokens, setTokens] = useState({business: -1, number: 0})
    const [price, setPrice] = useState({business: -1, number: 0})
    const {getData: fetchSmsTokens, postData: postTokens} = useCallDataApi('sms-token')
    const {getData: fetchSettings} = useCallDataApi('general')

    useEffect(() => {
        setLoading(true)
        Promise.all([fetchSmsTokens('get_for_business'), fetchSettings('get_price')])
            .then(([t, p]) => {
                setTokens(t)
                setPrice(p)
            }).finally(() => setLoading(false))
    }, [])

    const buySmsTokens = (size) => {
        setLoading(true)
        postTokens('buy_tokens/', {size: size})
            .then(response => window.location.href = response?.redirect)
            .catch(e => console.log(e, 'valami nem jó'))
            .finally(() => setLoading(false))
    }

    return <div className="white-card">
        <Typography variant='subtitle2'>SMS Tokenek</Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>Vásárolj sms tokeneket, egy token
            nagyjából egy smsnek felel meg. Olvass róla bővebben <a
                href="https://dinobooking.hu/sms-tokenek" target='_blank' rel="noreferrer">ide
                kattintva</a></Typography>
        <Typography variant='body2' color='#536B74' gutterBottom>Tokenek
            száma: {tokens?.number} db</Typography>

        <Grid container spacing={1} marginBottom={2} marginTop={2}>
            <Grid item xs={12}>
                <Stack direction='row' spacing={2} sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary[50],
                    borderRadius: '16px',
                    padding: '16px 24px'
                }}>
                    <SmsRoundedIcon
                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                    <Stack spacing={1}>
                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Kicsi SMS
                            csomag</Typography>
                        <Typography variant='body2' color='#38474D'>100 db token</Typography>
                        <Typography variant='subtitle2'
                                    color={theme.palette.primary[700]}>{thousandSeparator(price.small_sms_price)} Ft
                            ({price.small_sms_price / 100} Ft/db)</Typography>
                        <Button onClick={() => buySmsTokens('small')} variant='contained' sx={{
                            padding: '15px 12px 15px 16px',
                            width: 'fit-content'
                        }} endIcon={<ShoppingCartRoundedIcon/>}>Vásárlás</Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row' spacing={2} sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary[50],
                    borderRadius: '16px',
                    padding: '16px 24px'
                }}>
                    <SmsRoundedIcon
                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                    <Stack spacing={1}>
                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Közepes SMS
                            csomag</Typography>
                        <Typography variant='body2' color='#38474D'>500 db token</Typography>
                        <Typography variant='subtitle2'
                                    color={theme.palette.primary[700]}>{thousandSeparator(price.medium_sms_price)} Ft
                            ({price.medium_sms_price / 500} Ft/db)</Typography>
                        <Button onClick={() => buySmsTokens('medium')} variant='contained' sx={{
                            padding: '15px 12px 15px 16px',
                            width: 'fit-content'
                        }} endIcon={<ShoppingCartRoundedIcon/>}>Vásárlás</Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row' spacing={2} sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary[50],
                    borderRadius: '16px',
                    padding: '16px 24px'
                }}>
                    <SmsRoundedIcon
                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                    <Stack spacing={1}>
                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Nagy SMS
                            csomag</Typography>
                        <Typography variant='body2' color='#38474D'>1000 db token</Typography>
                        <Typography variant='subtitle2'
                                    color={theme.palette.primary[700]}>{thousandSeparator(price.big_sms_price)} Ft
                            ({price.big_sms_price / 1000} Ft/db)</Typography>
                        <Button onClick={() => buySmsTokens('big')} variant='contained' sx={{
                            padding: '15px 12px 15px 16px',
                            width: 'fit-content'
                        }} endIcon={<ShoppingCartRoundedIcon/>}>Vásárlás</Button>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>

        <Loading isLoading={loading}/>
    </div>
}

export default SmsTokens